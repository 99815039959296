<template>
  <div class="w-full px-6 ">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="min-w-full align-middle sm:px-6 lg:px-8">
        <div class="mb-8 flex flex-row items-center justify-between">
          <Select
              @value-changed="handlePageSize"
              key="view-pagination"
              :includeLabelInOptions="false"
              :label="`View`"
              :selected="pageSize.toString()"
              :options="totalRowsToDisplay"
          />
          <div>
            <div class="relative mt-2 rounded-md shadow-sm">
              <input type="text" name="employee-search"
                     v-model="searchString"
                     class="block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-[#AAAAAA] focus:ring-2
                            focus:ring-inset  sm:text-sm sm:leading-6" placeholder="Search"/>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <img src="/img/search.svg" class="w-6 h-6" alt="">
              </div>
            </div>
          </div>
        </div>
        <table v-if="!isLoading" class="min-w-full table-fixed divide-y divide-gray-300 border border-payne-grey font-roboto text-payne">
          <thead class="bg-box-bg font-bold text-base leading-5 border-payne-grey">
          <tr>
            <th scope="col" class="min-w-[140px] px-4 py-3.5 pr-3 text-left text-payne">Full Name</th>
            <th scope="col" class="min-w-[100px] px-3 py-3.5 text-left text-payne">Email</th>
            <th scope="col" class="min-w-[100px] px-3 py-3.5 text-left text-payne">Team</th>
            <th scope="col" class="min-w-[140px] px-3 py-3.5 text-left text-payne">Date Added</th>
            <th scope="col" class="max-w-[10px] px-3 py-3.5 text-left text-payne"></th>
          </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white font-normal text-sm leading-5">
          <tr v-for="(employee, userIndex) in employeeSearchResult?.employees"
              :key="employee.user.email">
            <td class="px-4 whitespace-nowrap py-4 pr-3 text-payne">
              {{ fullName(employee) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-payne">
              {{ employee?.user.email }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-payne">
              {{ employee?.userDemographics.find(ud => ud.demographic?.displayName == "Team")?.answer ?? 'No Team' }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-payne">
              {{ DateTime.fromISO(employee?.user.createdAt.toString()).toFormat('yyyy/MM/dd') }}
            </td>
            <td class="max-w-[10px] py-3.5 text-left text-payne">
              <Popover location="start">
                <template v-slot:popover_activator>
                  <div @click="handleMoreIconOrientation(userIndex)">
                    <img src="/img/kebab_menu.svg" alt="more vertical icon" :class="`w-6 h-6 cursor-pointer`">
                  </div>
                </template>
                <v-list-item style="margin: 0; padding: 0;">
                  <v-list-item-title class="pl-6 py-1 mb-4 mx-1 hover:bg-box-stroke">
                    <div
                        @click="$router.push(`/dashboard/entities/${activeEntity!.guid}/employees/${employee?.user.guid}`)"
                        class="cursor-pointer w-full">
                      <img src="/img/edit.svg" alt="edit icon" class="w-6 h-6 d-inline-block pr-3">
                      Edit {{ fullName(employee) }}
                    </div>
                  </v-list-item-title>
                  <v-list-item-title class="pl-6 py-1 mb-2 mx-1 hover:bg-box-stroke">
                    <DeleteModal :user="fullName(employee)" @cancelled="handleCancel"
                                 @deleted="handleDeleteEmployee(employee)"/>
                  </v-list-item-title>
                </v-list-item>
              </Popover>
            </td>
          </tr>
          </tbody>
        </table>

        <LoadingCard :is-loading="isLoading"/>

        <Pagination
            :totalRows="Math.floor(employeeSearchResult?.totalEmployees! / pageSize)"
            @page-changed="handlePagination"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue'
import Select from '../../AQuarks/Select.vue';
import Pagination from './Pagination.vue';
import Popover from '../../AQuarks/Popover.vue';
import Modal from '../../AQuarks/Modal.vue'
import {useRouter} from 'vue-router';
import {useEntityUserStore} from '@/areas/entities/store/entityUserStore'
import {useUserStore} from '@/areas/users/store/userStore';
import {ISelectOptions} from '@/types/shared';
import {DateTime} from 'luxon';
import Toast from '@/Toast';
import {Entity} from '@/areas/entities/model/data/dtos/entity';
import {UserRole} from "@/areas/users/model/enums/UserRole";
import {EmployeeDto} from '@/areas/entities/model/data/dtos/employeeDto'
import DeleteModal from "@/components/Atomic/BAtoms/Employees/DeleteModal.vue";
import {EmployeeSearchDto} from "@/areas/entities/model/data/dtos/employeeSearchDto";
import Loading from "@/shared/components/Loading.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";

const emits = defineEmits<{
  "update:selectedEmployees": [EmployeeDto[]],
  "moreIconToggled": [number],
  "employees-exist": []
}>()


const router = useRouter();
const userStore = useUserStore()
const entityUserStore = useEntityUserStore()
const pageNumber = ref<number>(0)
const pageSize = ref<number>(10)
const sortBy = ref<string>('FirstName')
const searchString = ref<string>('')
const sortAscending = ref<boolean>(true)

const isLoading = ref<boolean>(false)

const adminPriv = ref<boolean>(false);
const activeEntity = ref<Entity | undefined>(undefined);

const filteredEmployees = ref<EmployeeDto[]>([])

const employees = ref<EmployeeDto[]>([]);

const isModalOpen = ref<boolean>(false)

const employeeSearchResult = ref<EmployeeSearchDto | undefined>(undefined)

const checkRoles = async () => {
  const state = await userStore.getState();

  activeEntity.value = state.activeEntity

  switch (state.role) {
    case UserRole.SuperAdmin:
      adminPriv.value = true
      break

    case UserRole.OrganisationAdmin:
      adminPriv.value = false
      break

    case UserRole.User:
      await router.push(`/dashboard/assessments/${state.activeEntity.guid}`)
  }
}

const totalRowsToDisplay: ISelectOptions[] = [
  {option: '5', value: '5'},
  {option: '10', value: '10'},
  {option: '50', value: '50'},
  {option: '100', value: '100'}
]

const selectedEmployees = ref<EmployeeDto[]>([])

const employeeSearchDto = (): EmployeeSearchDto => {
  return {
    pageNumber: pageNumber.value + 1,
    pageSize: pageSize.value,
    sortBy: sortBy.value,
    searchString: searchString.value,
    sortAscending: sortAscending.value
  }
}

const fetchEmployees = async () => {
  if (!activeEntity.value) {
    return
  }

  isLoading.value = true

  const result = await entityUserStore.listUsers(activeEntity.value?.guid as string, employeeSearchDto())

  if (!result.isSuccessful) {
    Toast.error('Something went wrong while fetching employees', {
      position: 'top-right',
      dismissible: true,
      duration: 3000
    })
    isLoading.value = false
    return
  }

  if (result.content!.employees && result.content!.totalEmployees && result.content!.totalEmployees > 0) {
    emits('employees-exist')
  }

  employees.value = result.content!.employees ?? []
  employeeSearchResult.value = result.content!

  isLoading.value = false
}

const handlePagination = async (pageIndex: number) => {
  pageNumber.value = pageIndex

  await fetchEmployees()
}

const handlePageSize = async (size: string) => {
  pageSize.value = parseInt(size)

  await fetchEmployees()
}

const fullName = (employee: EmployeeDto) => {
  return `${employee.user.firstName} ${employee.user.lastName}`
}

const handleDelete = (action: string) => {
  const deletable = selectedEmployees.value
}

const handleMoreIconOrientation = (index: number) => {
  emits('moreIconToggled', index)
}

const handleCancel = () => {
  isModalOpen.value = true
}

const handleDeleteEmployee = async (user: EmployeeDto) => {
  const deleteResult = await entityUserStore.delete(activeEntity.value?.guid as string, user.user.guid as string)

  if (deleteResult.isSuccessful) {
    location.reload()
  }
}

onMounted(async () => {
  await checkRoles()
  await fetchEmployees()
})

watch(() => searchString.value, async () => {
  await fetchEmployees()
})

watch(selectedEmployees, (newVal) => {
  emits('update:selectedEmployees', newVal);
});


</script>