import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77bd34f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["placeholder", "required", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "text-red text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass(`pb-3 font-semibold ${!_ctx.isInvalidRef && !_ctx.customErrorMessage ? _ctx.labelColor : 'text-red'}`)
    }, _toDisplayString(_ctx.label), 3),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('input-on-focus'))),
      placeholder: _ctx.placeholder,
      required: _ctx.required,
      disabled: _ctx.disabled,
      class: _normalizeClass(`w-full pl-0 border-0 py-1.5 font-roboto text-payne-grey focus:ring-0 placeholder-gray-400
                    ${_ctx.isInvalidRef ? 'placeholder:text-red': ''} ${_ctx.disabled ? 'hover:cursor-not-allowed' : ''}`)
    }, null, 42, _hoisted_2), [
      [_vModelText, _ctx.value]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(`border-t peer-focus:border-t-2 peer-focus:border-payne-grey
            ${_ctx.isInvalidRef  ? 'border-red' : 'border-payne-grey'}`),
      "aria-hidden": "true"
    }, null, 2),
    ((_ctx.isInvalidRef && !_ctx.customErrorShow) || _ctx.customErrorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.customErrorMessage ? _ctx.customErrorMessage : 'Please complete this field'), 1))
      : _createCommentVNode("", true)
  ]))
}