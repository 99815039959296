import {defineStore} from 'pinia'
import {DemographicsProxy} from "@/areas/demographics/proxy/demographicsProxy";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import {Demographic} from "@/areas/demographics/model/data/dtos/demographic";
import {PopulatedDemographicDto} from "@/areas/demographics/model/data/dtos/PopulatedDemographicDto";
import {UserDemographic} from "@/areas/entities/model/data/dtos/userDemographicDto";
import {AnswerTypeEnum} from "@/areas/entities/model/data/dtos/answerTypeEnum";
import {Option} from "@/areas/entities/model/data/dtos/option";

interface DemographicEditStoreState {
    entityGuid?: string

    entityDemographics: Demographic[]

    populatedDemographics: PopulatedDemographicDto[]

    isLoading: boolean

    error: string | undefined
}

function blankState(): DemographicEditStoreState {
    return {
        entityGuid: undefined,

        entityDemographics: [],

        populatedDemographics: [],

        isLoading: true,

        error: undefined,
    }
}

function populatedDemographics(entityDemographics: Demographic[], userDemographics: UserDemographic[]): PopulatedDemographicDto[] {
    return entityDemographics.map(ed => {
        var userDemographic = userDemographics.find(ud =>
            ud.demographicGuid == ed.guid);

        if (!userDemographic) {
            userDemographic = {
                demographic: ed,
                demographicVersionGuid: ed.demographicVersions![0].guid!,
                answerType: AnswerTypeEnum.String,
                answer: undefined,
                demographicGuid: ed.guid!
            }
        }

        return {
            demographic: ed,
            demographicVersion: ed.demographicVersions![0],
            userDemographic: userDemographic,
            options: ToOptions(ed)
        }
    })
}

function ToOptions(demographicDto: Demographic): Option[] {
    if (demographicDto.demographicType === 'StaticCategory') {
        return demographicDto.demographicVersions!
            .flatMap(dv =>
                dv.payload.map(p => ({
                    option: p.title, // Use title for StaticCategory
                    value: p.title, // Use title as a unique identifier
                })));
    }

    if (demographicDto.demographicType === 'NumberedRange') {
        return demographicDto.demographicVersions!
            .flatMap(dv =>
                dv.payload.map(p => ({
                    option: `${p.min}-${p.max}`,
                    value: `${p.min}-${p.max}`
                })));
    }

    return []
}

export const useDemographicEditStore = defineStore({
    id: "demographicEditStore",
    state: (): DemographicEditStoreState => blankState(),
    getters: {},
    actions: {
        async init(entityGuid: string, employeeDto?: EmployeeDto) {
            await this.withLoading(async () => {
                this.clearState()

                const demographicsProxy = new DemographicsProxy()

                const entityDemographicsResult = await demographicsProxy.list(entityGuid)

                if (!entityDemographicsResult.isSuccessful) {
                    this.error = "Could not retrieve the demographics for the provided organisation, please make sure your are connected"
                    return
                }

                this.entityGuid = entityGuid
                this.entityDemographics = entityDemographicsResult.content!

                this.populatedDemographics = populatedDemographics(this.entityDemographics, employeeDto?.userDemographics ?? [])

                return
            });
        },

        select(demographicGuid: string, option?: string) {
            //Check if option is in the list
            const pIndex = this.populatedDemographics.findIndex(ud =>
                ud.demographic.guid == demographicGuid)

            if (pIndex == -1) {
                this.error = "The provided demographic does not exist"
                return
            }

            if (!option) {
                this.populatedDemographics[pIndex].userDemographic!.answer = undefined
                return
            }

            const selectedOption =
                this.populatedDemographics[pIndex]
                    .options
                    .find(op =>
                        op.option == option)

            if (selectedOption == null) {
                this.error = "Not a valid option to select"
                return
            }

            this.populatedDemographics[pIndex].userDemographic = {
                answer: selectedOption.value,
                demographicGuid: demographicGuid,
                demographicVersionGuid: this.populatedDemographics[pIndex].demographicVersion.guid!,
                answerType: AnswerTypeEnum.String
            };
        },

        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        clearError() {
            this.error = undefined
        },

        clearState() {
            this.$state = blankState()
        }
    }
});