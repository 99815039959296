import {AssessorProxy} from "@/areas/assessors/proxies/AssessorProxy";
import {AssessmentProxy} from "@/areas/assessments/proxy/assessmentProxy";
import {AssessmentTypeProxy} from "@/areas/assessmentTypes/proxy/assessmentTypeProxy";
import {AssessmentDto} from "@/areas/assessments/model/dtos/assessmentDto";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {DomainDo} from "@/areas/userAssessments/models/dos/DomainDo";
import {AnswerState} from "@/areas/userAssessments/models/enums/AnswerState";
import {QuestionDo} from "@/areas/userAssessments/models/dos/QuestionDo";
import {StatementPerspectiveType} from "@/areas/assessmentTypes/model/data/enums/StatementPerspectiveType";
import {DomainResultDto} from "@/areas/userAssessments/models/dtos/DomainResultDto";
import {defineStore} from "pinia";
import {AssessorDto} from "@/areas/assessors/models/dtos/assessorDto";
import {DomainUpsertResultDto} from "@/areas/userAssessments/models/dtos/DomainUpsertResultDto";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {CommentResultDto} from "@/areas/userAssessments/models/dtos/CommentResultDto";
import {CommentResultType} from "@/areas/userAssessments/models/enums/CommentResultType";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {DomainDto} from "@/areas/assessmentTypes/model/data/dto/DomainDto";
import {DomainType} from "@/areas/assessmentTypes/model/data/enums/DomainType";
import {sortBy} from "lodash";


interface StoreState {
    assessment: AssessmentDto | undefined

    assessor: AssessorDto | undefined

    assessmentType: AssessmentTypeDto | undefined

    delegate: DelegateDto | undefined

    commentResults: CommentResultDto[]

    resultState: DomainDo[]

    activePage: DomainDo | undefined

    activeIndex: number

    state: AnswerState | undefined,

    error?: string | undefined

    entityGuid: string | undefined

    isLoading: boolean
}

function resetStore(): StoreState {
    return {
        assessment: undefined,

        assessor: undefined,

        assessmentType: undefined,

        delegate: undefined,

        commentResults: [],

        resultState: [],

        activePage: undefined,

        activeIndex: 0,

        state: undefined,

        error: undefined,

        entityGuid: undefined,

        isLoading: true
    }
}

function buildState(assessmentType: AssessmentTypeDto, results: DomainResultDto[]): DomainDo[] {
    let domains: DomainDto[] = assessmentType.domains
    domains = domains.filter(d => d.deletedAt == null)

    const welcomeDomains = domains
        .filter(d  => d.domainType == DomainType.Welcome)

    const assessorDomains = sortBy(domains
        .filter(d => d.domainType == DomainType.AssessDomain), "key")

    const completionDomains = domains
        .filter(d  => d.domainType == DomainType.Completion)

    domains = welcomeDomains
    domains.push(...assessorDomains)
    domains.push(...completionDomains)

    const questionResults = results.flatMap(r => r.questionResults)

    let domainDos: DomainDo[] = []

    for (const domain of domains) {
        const questions = sortBy(domain.questions, "key")

        let domainDo: DomainDo = {
            guid: domain.guid!,
            key: domain.key,
            name: domain.name,
            state: AnswerState.NotStarted,
            domainType: domain.domainType,
            isUpdated: false,
            questions: []
        }

        const domainResult = results.find(d => d.domain.guid === domain.guid)

        if (domainResult) {
            domainDo.comments = domainResult.comments
        }

        for (const question of questions) {

            let questionDo: QuestionDo = {
                guid: question.guid!,
                first: question.statements.find(st => st.statementPerspectiveType == StatementPerspectiveType.First),
                third: question.statements.find(st => st.statementPerspectiveType == StatementPerspectiveType.Third),
                title: question.title
            }

            const result = questionResults.find(qr => qr.question.guid == question.guid)

            if (result != null) {
                questionDo.rating = result.rating
            }

            domainDo.questions.push(questionDo)
        }

        const completedCount = domainDo.questions.filter(q => q.rating).length;

        if (completedCount == domainDo.questions.length) {
            domainDo.state = AnswerState.Completed
        } else if (completedCount > 0) {
            domainDo.state = AnswerState.InProgress
        } else {
            domainDo.state = AnswerState.NotStarted
        }

        domainDos.push(domainDo)
    }

    return domainDos
}

export const useQuestionStore = defineStore({
    id: "questionStore",
    state: (): StoreState => resetStore(),
    getters: {
        isValidState(state: StoreState) {
            return (comment: string): boolean => {
                const inCompleteResults = this.activePage?.questions.find(q => !q.rating || q.rating == 0)

                if (inCompleteResults) {
                    return false
                }

                return comment.length >= 10;
            }
        }
    },
    actions: {
        async init(entityGuid: string, assessmentDisplayObject: AssessmentDisplayObject, assessor: AssessorDto, delegate: DelegateDto): Promise<void> {
            await this.withLoading(async () => {
                const assessmentGuid = assessmentDisplayObject.assessment.guid!

                const assessmentProxy = new AssessmentProxy()
                const assessmentResult = await assessmentProxy.getByGuid(entityGuid, assessmentGuid)

                const assessorProxy = new AssessorProxy()

                if (!assessmentResult.isSuccessful) {
                    this.error = "The assessment could not be retrieved"
                    return
                }

                const assessment = assessmentResult.content!

                const assessmentTypeGuid = assessment.entityAssessmentType!.assessmentType.guid!

                const assessmentTypeResult = await AssessmentTypeProxy.get(entityGuid, assessmentTypeGuid)

                if (!assessmentTypeResult.isSuccessful) {
                    this.error = "The assessment type for the assessment could not be retrieved"
                    return
                }

                const assessmentCommentResults = await assessorProxy.listResultComments(
                    entityGuid,
                    assessmentGuid,
                    delegate.guid!,
                    assessor.guid!);

                if (!assessmentCommentResults.isSuccessful) {
                    this.error = "Could not retrieve the assessment comment results for the user"
                    return
                }

                this.commentResults = assessmentCommentResults.content!
                this.assessmentType = assessmentTypeResult.content!
                this.assessment = assessment
                this.assessor = assessor
                this.delegate = delegate
                this.entityGuid = entityGuid

                await this.setState()
            })
        },

        back() {
            if (this.activeIndex === 0) {
                this.state = AnswerState.NotStarted
                return
            }

            this.activeIndex = this.activeIndex - 1
            this.activePage = this.resultState[this.activeIndex]
            this.state = AnswerState.InProgress
        },

        setRating(question: QuestionDo, rating: number) {
            if (!this.activePage) {
                return
            }

            let questionIndex = this.activePage?.questions.findIndex(q => q.guid === question.guid);

            if (questionIndex === -1) {
                this.error = "Could not find the question to set the rating for"
                return
            }

            if (this.activePage.questions[questionIndex].rating !== rating && rating !== 0) {
                this.activePage.isUpdated = true
            }

            this.activePage.questions[questionIndex].rating = rating;
        },

        async next(comment: string) {
            await this.withLoading(async () => {
                this.error = undefined

                if (this.state == AnswerState.NotStarted) {
                    this.state = AnswerState.InProgress
                    return
                }

                if (!this.isValidState(comment)) {
                    return
                }

                if (this.activePage?.state == AnswerState.Completed && !this.activePage.isUpdated && this.activePage.comments === comment && this.state != AnswerState.Completed) {
                    //Reached the end of the road
                    if (this.resultState.length - 2 == this.activeIndex) {
                        this.state = AnswerState.Completed
                    } else {
                        this.state = AnswerState.InProgress
                    }

                    this.activeIndex = this.activeIndex + 1
                    this.activePage = this.resultState[this.activeIndex]

                    return
                }

                const assessorProxy = new AssessorProxy()

                const questionUpsertDtos = this.activePage!.questions.map(question => {
                    return {
                        questionGuid: question.guid,
                        rating: question.rating!
                    }
                })

                const domainResultDto: DomainUpsertResultDto = {
                    domainGuid: this.activePage?.guid!,
                    comment: comment,
                    questionUpsertResults: questionUpsertDtos
                };

                const result = await assessorProxy.upsertResult(
                    this.entityGuid!,
                    this.assessment!.guid!,
                    this.delegate!.guid!,
                    this.assessor?.guid!,
                    domainResultDto)

                if (!result.isSuccessful) {
                    this.error = "Could not save the assessment result"
                    return
                }

                await this.setState()
            })
        },

        async complete(towardComment: string, awayComment: string) {

            if (this.state != AnswerState.Completed) {
                return
            }

            await this.withLoading(async () => {
                const assessorProxy = new AssessorProxy();

                var commentResults: CommentResultDto[] = [
                    {
                        commentResultType: CommentResultType.Away,
                        comment: awayComment
                    },
                    {
                        commentResultType: CommentResultType.Toward,
                        comment: towardComment
                    }
                ]

                const result = await assessorProxy.complete(
                    this.entityGuid!,
                    this.assessment!.guid!,
                    this.delegate!.guid!,
                    this.assessor?.guid!,
                    commentResults
                );

                if (!result.isSuccessful) {
                    this.error = "Could not complete the assessment at this time. Please try again later";
                    return;
                }

                await this.setState();
            });
        },

        async setState() {
            const assessorProxy = new AssessorProxy()

            const answerResult = await assessorProxy.listResults(
                this.entityGuid!,
                this.assessment?.guid!,
                this.delegate?.guid!,
                this.assessor!.guid!,
                this.assessor!.user!.guid!)

            if (!answerResult.isSuccessful) {
                this.error = "Could not retrieve the answers for this user"
                return
            }

            this.resultState = buildState(this.assessmentType!, answerResult.content!)

            let activeIndex = this.resultState.findIndex(rs => (rs.state == 'NotStarted' || rs.state == 'InProgress') && rs.domainType != DomainType.Completion)

            if (activeIndex == -1) {
                this.state = AnswerState.Completed
                activeIndex = this.resultState.length - 1
            } else if (activeIndex == 0) {
                this.state = AnswerState.NotStarted
            } else {
                this.state = AnswerState.InProgress
            }

            this.activeIndex = activeIndex
            this.activePage = this.resultState[this.activeIndex]
        },

        continueEdit() {
            if (this.state === AnswerState.Completed) {
                this.state = AnswerState.InProgress
            }
        },

        async withLoading(action: () => void) {
            try {
                this.isLoading = true;

                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        clearError() {
            this.error = undefined
        },

        clearState() {
            this.$state = resetStore()
        },
    }

});