import axiosInstance from "@/axiosConfig";
import {EmployeeSearchDto} from "../model/data/dtos/employeeSearchDto";
import {ProblemDetails} from "@/areas/helpers/responses/models/problemDetails";
import {EmployeeCreateDto} from "../model/data/dtos/employeeCreateDto";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {CancelTokenSource} from "axios";
import {Operations} from "@/areas/helpers/responses/operations";

export class EntityUserProxy {
    public async upsert(entityGuid: string, employeeCreateDto: EmployeeCreateDto):Promise<OperationResult<EmployeeDto>> {
        const uri = `/entities/${entityGuid}/users`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<EmployeeDto>(uri, employeeCreateDto)

            return Operations.ToSuccessResponse(response.data);
        })
    }

    public async getUser(entityGuid: string, userGuid: string): Promise<OperationResult<EmployeeDto>> {
        const uri = `/entities/${entityGuid}/users/${userGuid}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<EmployeeDto>(uri)

            return Operations.ToSuccessResponse(response.data);
        })
    }

    public async getUserByEmail(entityGuid: string, userEmail: string): Promise<OperationResult<EmployeeDto>> {
        const uri = `/entities/${entityGuid}/users/emails/${userEmail}`;

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<EmployeeDto>(uri)

            return Operations.ToSuccessResponse(response.data);
        })
    }

    public async listUsers(
        entityGuid: string,
        employeeSearchDto: EmployeeSearchDto,
        cancellationToken: CancelTokenSource | undefined = undefined): Promise<OperationResult<EmployeeSearchDto>> {
        
        const uri = `/entities/${entityGuid}/users`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<EmployeeSearchDto>(uri, {
                params: employeeSearchDto,
                cancelToken: cancellationToken?.token
            })

            return Operations.ToSuccessResponse(response.data);
        })
    }

    public async delete(entityGuid: string, userGuid: string) {
        const uri = `/entities/${entityGuid}/users/${userGuid}`

        try {
            const response = await axiosInstance.delete(uri);

            return Operations.ToSuccessResponse(response.data)

        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }


    public async getEmployeeTemplateFile(entityGuid: string) {
        const uri = `/entities/${entityGuid}/users/files/templates`

        try {
            const response = await axiosInstance.get(uri)

            return Operations.ToSuccessResponse(response.data);

        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async getEmployeesByDemographicCategory(entityGuid: string, demographicGuid: string, categoryValue: string, answer: string): Promise<OperationResult<EmployeeDto[]>>
    {
        const uri = `/entities/${entityGuid}/users/demographics/${demographicGuid}/categories/${categoryValue}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<EmployeeDto[]>(uri, {
                params: {
                    answer: answer
                }
            })

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async uploadFile(entityGuid: string, file: File) {
        const uri = `/entities/${entityGuid}/users/files/uploads`

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axiosInstance.post(uri, formData);

            return Operations.ToSuccessResponse(response.data)

        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }
}