import {ValidationBlock} from "@/areas/helpers/validations/ValidationBlock";
import {UserRolesEnum} from "@/areas/demographics/model/enums/userRolesEnum";
import {ValidationGroup} from "@/areas/helpers/validations/ValidationGroup";

export class UserValidationGroup extends ValidationGroup {
    email: ValidationBlock<string>
    firstName: ValidationBlock<string>
    lastName: ValidationBlock<string>
    dateOfBirth: ValidationBlock<Date>
    mobileNumber: ValidationBlock<string>
    userRole: ValidationBlock<UserRolesEnum>

    constructor() {
        super();

        this.email = new ValidationBlock<string>("", [
            (value) => (value ? undefined : "Email is required."),
            (value) =>
                value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
                    ? undefined
                    : "Invalid email format.",
        ]);

        this.firstName = new ValidationBlock<string>("", [
            (value) => (value ? undefined : "First name is required."),
        ]);

        this.lastName = new ValidationBlock<string>("", [
            (value) => (value ? undefined : "Last name is required."),
        ]);

        this.dateOfBirth = new ValidationBlock<Date>(undefined, [
            (value) =>
                !value || (value && !isNaN(value.getTime()))
                    ? undefined
                    : "Invalid date of birth.",
        ]);

        this.mobileNumber = new ValidationBlock<string>("", [
            (value) =>
                value == undefined || value.trim() == ""  || (value && /^\d{10}$/.test(value))
                    ? undefined
                    : "Mobile number must be 10 digits.",
        ]);

        this.userRole = new ValidationBlock<UserRolesEnum>(undefined, [
            (value) =>
                value ? undefined : "User role is required.",
        ]);
    }
}