import {defineStore} from 'pinia'
import {EntityUserProxy} from "@/areas/entities/proxy/entityUserProxy";
import {DemographicsProxy} from "@/areas/demographics/proxy/demographicsProxy";
import {Demographic} from "@/areas/demographics/model/data/dtos/demographic";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import {ParticipantDo} from "@/areas/participants/models/dos/ParticipantDo";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {useEmployeeSearchStore} from "@/areas/employees/stores/EmployeeSearchStore";

interface ParticipantState {
    userEntityProxy: EntityUserProxy

    demographicProxy: DemographicsProxy

    teamDemographic: Demographic | undefined

    teams: string[]

    employees: EmployeeDto[] | undefined

    teamName: string | undefined

    delegateType: DelegateType | undefined

    entityGuid: string | undefined

    error: string | undefined

    isLoading: boolean
}

function clearStore(): ParticipantState {
    return {
        userEntityProxy: new EntityUserProxy(),

        demographicProxy: new DemographicsProxy(),

        teamDemographic: undefined,

        teams: [],

        employees: undefined,

        teamName: undefined,

        delegateType: undefined,

        entityGuid: undefined,

        error: undefined,

        isLoading: true
    }
}

export const useParticipantStore = defineStore({
    id: "participantStore",
    state: (): ParticipantState => clearStore(),
    getters: {
        getParticipantDo: (state) => {
            return (): ParticipantDo => {
                return {
                    delegateType: state.delegateType!,
                    employees: state.employees!,
                    teamName: state.teamName
                }
            }
        }
    },
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        async init(entityGuid: string): Promise<void> {
            await this.withLoading(async () => {
                const entityDemographicsFetchResult = await this.demographicProxy.list(entityGuid);

                if (!entityDemographicsFetchResult.isSuccessful) {
                    this.error = 'Demographics not found'
                    return
                }

                const teamsDemographic = entityDemographicsFetchResult.content!.find(demographic => demographic.displayName === 'Team')

                if (teamsDemographic) {
                    this.teams = teamsDemographic.demographicVersions![0].payload.map(team => team.title)
                    this.teamDemographic = teamsDemographic
                }

                this.entityGuid = entityGuid
            })
        },

        async addEmployee(employee: EmployeeDto) {
            this.delegateType = DelegateType.Individual

            if (!this.employees) {
                this.employees = []
            }

            const existingEmployee = this.employees.find(emp => emp.user.guid == employee.user.guid)

            if (!existingEmployee) {
                this.employees.push(employee)
            }
        },

        async removeEmployee(employee: EmployeeDto) {
            this.employees = this.employees?.filter(emp => emp.user.guid != employee.user.guid)
        },

        async setTeamEmployees(teamName: string): Promise<void> {
            await this.withLoading(async () => {
                this.clearTeamEmployees()

                if (!this.teamDemographic) {
                    return
                }

                const result = await this.userEntityProxy.getEmployeesByDemographicCategory(
                    this.entityGuid!,
                    this.teamDemographic.guid!,
                    "Team",
                    teamName)

                if (!result.isSuccessful) {
                    this.error = "Could not retrieve the list of employees for the team selected"
                    return
                }

                this.delegateType = DelegateType.Team
                this.teamName = teamName
                this.employees = result.content!
            })
        },

        async refreshStore() {
            const employeeSearchStore = useEmployeeSearchStore()

            await employeeSearchStore.searchEmployees(this.entityGuid!, employeeSearchStore.currentSearchTerm)
        },

        clearTeamEmployees() {
            this.employees = undefined
        },

        clearError() {
            this.error = undefined
        },

        clearState() {
            this.$state = clearStore()
        },
    }

});