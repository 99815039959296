<template>
  <div class="w-full flex flex-col justify-center items-center pt-4">
    <div class="sm:max-w-2xl w-full">

      <div v-if="!addDelegate && !editDelegate" class="flex flex-col space-y-4">

        <NavHeading
            heading="Manage Delegates"
            @back="back(false)"
            class="py-4">

          <div class="flex flex-col items-end">
            <AddButton
                :disabled="delegateStore.assessment != undefined && [AssessmentState.Completed, AssessmentState.Cancelled].includes(delegateStore.assessment.entityState)"
                @add="addDelegate = true"/>
          </div>
        </NavHeading>

        <SearchInputCard class="w-full" placeholder="Search Delegates" @searchUpdate="search"></SearchInputCard>

        <LoadingCard :isLoading="delegateStore.isLoading"></LoadingCard>

        <div v-if="!delegateStore.isLoading" class="space-y-4">

          <EmptyAddCard
              v-if="delegateStore.delegates.length === 0"
              @add="addDelegate = true">

            There are currently no delegates configured for his assessment. Please press the '+' button to add a
            delegate
          </EmptyAddCard>

          <Collapsable
              v-show="newDelegates.length !== 0"
              :is-open="newDelegateOpen"
              :title="`New Delegates (${newDelegates.length})`"
              @isOpen="val => newDelegateOpen = val">

            <div class="space-y-2">
              <DelegateCard
                  v-for="delegate in newDelegates"
                  :key="delegate.key"
                  :delegate="delegate"
                  :assessment-state="assessment.assessment.entityState"
                  :assessment-detail="assessment.detail"
                  @edit="edit(delegate)"
                  @delete="deleteDelegate(delegate)"
                  @report="reportDelegateGuid = delegate.delegateDto?.guid"
                  @selectOwnAssessor="val => delegateStore.toggleSelectOwnAssessors(delegate, val)"/>
            </div>
          </Collapsable>

          <Collapsable
              v-show="editedDelegates.length !== 0"
              :is-open="editDelegateOpen"
              :title="`Updated Delegates (${editedDelegates.length})`"
              @isOpen="val => editDelegateOpen = val">

            <DelegateCard
                v-for="delegate in editedDelegates"
                :key="delegate.key"
                :delegate="delegate"
                :assessment-state="assessment.assessment.entityState"
                :assessment-detail="assessment.detail"
                @edit="edit(delegate)"
                @delete="deleteDelegate(delegate)"
                @report="reportDelegateGuid = delegate.delegateDto?.guid"/>
          </Collapsable>

          <Collapsable
              :is-open="existingDelegateOpen"
              :title="`Existing Delegates (${existingDelegates.length})`"
              @isOpen="val => existingDelegateOpen = val">

            <div class="space-y-2">
              <DelegateCard
                  v-for="delegate in existingDelegates"
                  :key="delegate.key"
                  :delegate="delegate"
                  :assessment-state="assessment.assessment.entityState"
                  :assessment-detail="assessment.detail"
                  @edit="edit(delegate)"
                  @delete="deleteDelegate(delegate)"
                  @report="reportDelegateGuid = delegate.delegateDto?.guid"
                  @sendReminder="sendReminder(delegate)"
              />
            </div>
          </Collapsable>

          <div class="pb-10"></div>

          <SaveButton :isLoading="false" class="px-2" @save="save" :disabled="false" text="Confirm"/>

        </div>

        <div class="pb-4"></div>

      </div>

      <ReportGenerator
          v-if="reportDelegateGuid"
          :assessment-guid="assessment.assessment.guid!"
          :entity-guid="entityGuid"
          :delegate-guid="reportDelegateGuid!"
          @complete="onReportGenerated()"/>

      <AssessorManager
        v-if="editDelegate"
        :delegate="editDelegate"
        :assessment="delegateStore.assessment!"
        :entity-guid="entityGuid"
        :force-validation="false"
        :draft-assessors="editDelegate.assessors.filter(as => as.assessorState == AssessorState.Draft)"
        @back="editDelegate = undefined"
        @save="addAssessors"/>

      <AddParticipants
          v-if="addDelegate"
          :selected-users="delegateStore.getIndividualUsers()"
          :heading="'Add Delegates'"
          :selectable="true"
          :entity-guid="entityGuid"
          :can-deselect="false"
          :can-create="false"
          @save="addDelegates"
          @back="reset"/>

      <PopupErrorMessage
          v-if="delegateStore.error"
          :message="delegateStore.error"
          @closed="delegateStore.clearError()"
      />

      <PopupConfirmation
          v-if="confirmBack"
          title="Unsaved Changes"
          @confirm="back(true)"
          @cancel="confirmBack = false"
      >
        You have unsaved delegates. If you continue you will discard all changes
      </PopupConfirmation>

      <PopupConfirmation
          v-if="reportIsDownloading"
          title="Download in Progress"
          confirmText="Ok"
          :ignore-cancel="true"
          @confirm="reportIsDownloading = false"
          @cancel="confirmBack = false"
      >
        The report download should start shortly...
      </PopupConfirmation>

    </div>
  </div>
</template>

<script setup lang="ts">

import {useDelegateStore} from "@/areas/delegates/store/DelegateStore";
import SearchInputCard from "@/shared/components/SearchInputCard.vue";
import {computed, onBeforeUnmount, onMounted, ref, Ref, watch} from "vue";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import Toast from "@/Toast";
import AddButton from "@/shared/components/AddButton.vue";
import EmptyAddCard from "@/shared/components/EmptyAddCard.vue";
import NavHeading from "@/shared/components/NavHeading.vue";
import DelegateCard from "@/areas/delegates/components/DelegateCard.vue";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import {DelegateDisplayItem} from "@/areas/delegates/model/dos/DelegateDisplayItem";
import ReportGenerator from "@/components/Atomic/BAtoms/Reports/ReportGenerator.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import SaveButton from "@/shared/components/SaveButton.vue";
import AddParticipants from "@/areas/participants/components/AddParticipants.vue";
import Collapsable from "@/shared/components/Collapsable.vue";
import {ParticipantDo} from "@/areas/participants/models/dos/ParticipantDo";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import {DelegateDisplayState} from "@/areas/delegates/model/enums/DelegateDisplayState";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import AssessorManager from "@/areas/assessors/components/AssessorManager.vue";
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";

const props = defineProps<{
  assessment: AssessmentDisplayObject
  entityGuid: string
}>()

const emits = defineEmits<{
  back: []
}>()

const newDelegates = computed(() => {
  return delegateStore.getNew
})

const existingDelegates = computed(() => {
  return delegateStore.getExisting
})

const editedDelegates = computed(() => delegateStore.getByDisplayState(DelegateDisplayState.Edited))

const delegateStore = useDelegateStore()

const addDelegate = ref<boolean>(false)

const editDelegate: Ref<DelegateDisplayItem | undefined> = ref(undefined)

const reportDelegateGuid = ref<string | undefined>(undefined)

const reportIsDownloading = ref<boolean>(false)

const existingDelegateOpen = ref<boolean>(true)

const newDelegateOpen = ref<boolean>(false)

const editDelegateOpen = ref<boolean>(false)

const confirmBack = ref<boolean>(false)

const reset = () => {
  addDelegate.value = false

  editDelegate.value = undefined
}

const edit = (delegate: DelegateDisplayItem) => {
  editDelegate.value = delegate
}

const deleteDelegate = async (delegate: DelegateDisplayItem) => {
  await delegateStore.deleteDelegate(delegate)

  if (delegateStore.error) {
    Toast.error(delegateStore.error, toastOptions)

    return
  }

  Toast.success("Successfully deleted delegate", toastOptions)
}

const search = (searchString: string) => {
  delegateStore.search(searchString)
}

const addDelegates = (participantDo: ParticipantDo) => {
  addDelegate.value = false

  newDelegateOpen.value = true

  delegateStore.addDelegates(participantDo)
}

const save = async () => {
  await delegateStore.save()

  if (!delegateStore.error) {
    Toast.success("Successfully saved delegates", toastOptions)
  }
}

const back = (force: boolean = false) => {
  if (!force) {
    const unsavedDelegates = delegateStore.delegates.find(d => [DelegateDisplayState.New, DelegateDisplayState.Edited].includes(d.delegateDisplayState))

    if (unsavedDelegates) {
      confirmBack.value = true
      return
    }
  }

  emits('back')
}

const sendReminder = (delegate: DelegateDisplayItem) => {
  delegateStore.sendReminder(delegate)

  if (!delegateStore.error) {
    Toast.success("Successfully sent reminder", toastOptions)
  }
}

const addAssessors = (assessors: AssessorDisplayObject[]) => {
  if (!editDelegate) {
    return
  }

  delegateStore.updateAssessors(editDelegate.value!, assessors)

  if (delegateStore.error) {
    Toast.error(delegateStore.error, toastOptions)

    return
  }

  editDelegateOpen.value = true

  Toast.success("Successfully added assessors", toastOptions)

  editDelegate.value = undefined
}

const handleBeforeUnload = (event: Event) => {
  const unsavedDelegates = delegateStore.delegates.find(d => [DelegateDisplayState.New, DelegateDisplayState.Edited].includes(d.delegateDisplayState))

  if (unsavedDelegates) {
    event.preventDefault()
    // event.returnValue = '' // This line is necessary for the prompt to show up in some browsers
  }
}

const onReportGenerated = () => {
  reportIsDownloading.value = true

  reportDelegateGuid.value = undefined
}

const handlePopState = () => {
  back(false)
}

watch(() => delegateStore.error, (newValue) => {
  if (newValue) {
    Toast.error(newValue, toastOptions)
  }
})

onMounted(async () => {
  await delegateStore.init(props.assessment.assessment.guid!, props.entityGuid)

  // Add event listener for beforeunload event
  window.addEventListener('beforeunload', handleBeforeUnload)

  // Add event listener for popstate event to detect browser back button
  window.addEventListener('popstate', handlePopState)
})

onBeforeUnmount(() => {
  // Remove event listeners when component is unmounted
  window.removeEventListener('beforeunload', handleBeforeUnload)
  window.removeEventListener('popstate', handlePopState)
})
</script>