<template>
  <button
      @click="emit('add')"
      :class="`${disabled ? 'disabled bg-gray-400' : 'bg-olive-green'} text-white font-bold py-2 px-4 rounded items-center justify-center transform active:scale-90 transition duration-100`">
    <svg v-if="!heading" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
    </svg>

    <div v-if="heading">
      {{heading}}
    </div>
  </button>
</template>

<script setup lang="ts">

defineProps<{
  disabled: boolean
  heading?: string
}>()

const emit = defineEmits<{
  add: []
}>()

</script>