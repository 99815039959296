<template>
  <div>
    <p :class="`font-semibold ${!isInvalidRef && !customErrorMessage ? 'text-payne-grey': 'text-red'}`">{{ label }}</p>
    <div class="relative h-10 text-sm">
      <select
          v-model="selectedRef"
          :disabled="disabled"
          :class="`pl-0 w-full border-0 py-1.5 font-roboto text-payne-grey focus:ring-0
          ${isInvalidRef ? 'text-red' : ''} ${disabled ? 'hover:cursor-not-allowed' : ''}`"
      >
        <!-- Placeholder Option -->
        <option
            v-if="includeLabelInOptions && selectedRef === ''"
            value=""
            disabled
            class="placeholder-option text-gray-200"
        >
          {{ placeholder || `Select ${label.toLowerCase()}` }}
        </option>

        <!-- Render Options -->
        <option
            v-for="item in options"
            :key="item.value"
            :value="item.value"
        >
          {{ item.option }}
        </option>
      </select>

      <div
          class="absolute inset-x-0 bottom-0 border-t border-payne-grey peer-focus:border-t-2 peer-focus:border-payne-grey"
          aria-hidden="true"
      />
    </div>
    <p v-if="isInvalidRef || customErrorMessage" class="text-red text-xs">{{ customErrorMessage ? customErrorMessage : "Please select a value"}}</p>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from "vue";

export default defineComponent({
  name: "Select",
  props: {
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array as () => { option: string, value: string }[],
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
    includeLabelInOptions: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    customErrorMessage: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
  },

  setup(props, context) {
    const selectedRef = ref<string>();
    const isInvalidRef = ref<boolean>(props.isInvalid)

    watch(() => props.isInvalid, (newValue) => {
      isInvalidRef.value = newValue;
    });

    onMounted(() => {
      selected()
    })

    const selected = () => {
      if (props.selected != null) {

        const selectedValue = props.options.find(op => op.value == props.selected)

        if (selectedValue != undefined && selectedRef.value != selectedValue?.value) {
          selectedRef.value = selectedValue?.value
          context.emit('value-changed', selectedRef.value)
        }
      }
    }

    watch(() => props.selected,() =>{
      selected()
    })

    watch (() => selectedRef.value, (old, newValue) => {
      if (old != newValue) {
        context.emit('value-changed', selectedRef.value)
      }
    })

    return {
      selectedRef,
      isInvalidRef
    };
  },
});
</script>

<style scoped>
.placeholder-option {
  color: #c0c0c0; /* Lighter grey */
}
</style>