<template>
  <AppLayout>
    <div class="px-2 md:px-0">
      <div v-if="addAssessment">
        <AssessmentForm
            :assessment-types="assessmentStore.assessmentTypes"
            :entity-guid="entityGuid"
            :assessment-display-object="editAssessment"
            @back="clearEdit"
            @saved="saveSuccess"/>

      </div>

      <div v-if="!addAssessment && !manageDelegateAssessment">
        <AssessmentsView
            :is-loading="assessmentStore.isLoading"
            :assessments="assessmentStore.assessments"
            @addAssessment="addAssessment = true"
            @edit="edit"
            @setStatus="setStatus"
            @manageDelegates="manageDelegates"/>
      </div>
      <div v-if="manageDelegateAssessment">
        <DelegateList
            :entity-guid="entityGuid"
            :assessment="manageDelegateAssessment"
            @back="manageDelegateAssessment = undefined">
        </DelegateList>
      </div>

    </div>

  </AppLayout>
</template>

<script setup lang="ts">
import {useRoute, useRouter} from 'vue-router';
import {onMounted, Ref, ref} from 'vue';
import AppLayout from '@/components/Layouts/AppLayout.vue';
import AssessmentsView from "@/areas/assessments/components/AssessmentsList.vue";
import {useAssessmentStore} from "@/areas/assessments/store/AssessmentStore";
import AssessmentForm from "@/areas/assessments/components/AssessmentForm.vue";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import Toast from "@/Toast";
import {ToastProps} from "vue-toast-notification";
import bus from '@/bus'
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import DelegateList from "@/areas/delegates/components/DelegateList.vue";

const route = useRoute();
const router = useRouter();

const assessmentStore = useAssessmentStore()

const addAssessment: Ref<boolean> = ref(false)

const manageDelegateAssessment: Ref<AssessmentDisplayObject | undefined> = ref(undefined)

const editAssessment: Ref<AssessmentDisplayObject | undefined> = ref(undefined)

const entityGuid: Ref<string> = ref(route.params.entityGuid as string)

const toastOptions: ToastProps = {
  duration: 5000,
  position: 'top-right',
  dismissible: true
}

const edit = (assessment: AssessmentDisplayObject) => {
  editAssessment.value = assessment

  addAssessment.value = true
}

const manageDelegates = (assessment: AssessmentDisplayObject) => {
  manageDelegateAssessment.value = assessment
}

const setStatus = async (assessment: AssessmentDisplayObject, state: AssessmentState) => {
  bus.emit('close-popover-menu')

  clearEdit()

  await assessmentStore.setState(entityGuid.value, assessment, state)

  if (assessmentStore.error) {
    await Toast.error(assessmentStore.error, toastOptions)

    assessmentStore.clearError()
  }
}

const clearEdit = () => {
  addAssessment.value = false

  editAssessment.value = undefined
}

const saveSuccess = () => {
  clearEdit()

  Toast.success("Assessment saved successfully", toastOptions)
}

onMounted(async () => {
  await assessmentStore.init(entityGuid.value)
})

</script>