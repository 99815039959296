<template>
  <div>
    <div class="tab__header">
      <div
          :class="`cursor-default hover:cursor-default mb-1 p-4 bg-gray-200 ${selectedDemographic.demographicEnabled ? 'hover:bg-gray-300' : ''} text-payne-grey flex justify-between rounded`">
        <div class="flex">
          <div :class="`${selectedDemographic.demographicEnabled ?'cursor-pointer' :  ''}`"
               @click="toggleAccordion(index)">
            <img src="/img/arrow_drop_down.svg" v-if="selectedDemographic.demographicEnabled" alt="arrow right icon"
                 :class="`w-6 h-6 ${active ? '' : '-rotate-90'}`"/>
            <img src="/img/dropdown_arrow_disable.svg" v-else alt="arrow down icon" class="w-6 h-3 mt-1"/>
          </div>
          <p :class="`font-medium ${selectedDemographic.demographicEnabled ? '' : 'text-light-grey'}`">
            {{ selectedDemographic.demographicTitle }}</p>
        </div>
        <Popover location="start">
          <template v-slot:popover_activator>
            <div @click="handleMoreIconOrientation(index)">
              <img v-if="isActive" src="/img/kebab_menu_selected.svg" alt="more horizontal icon"
                   :class="`w-6 h-6 cursor-pointer`">
              <img v-else src="/img/kebab_menu.svg" alt="more vertical icon" :class="`w-6 h-6 cursor-pointer`">
            </div>
          </template>

          <v-list-item class="m-0 p-0">
            <v-list-item-title @click="emitModalOpened(index, $event)"
                               :class="`pl-6 py-1 mb-4 mx-1 hover:bg-box-stroke  ${activeActionIndex === 1 ? 'bg-box-stroke' : ''}`">
              <DemographicModal :demographicTitle="selectedDemographic.demographicTitle"
                                :selectedDemographic="selectedDemographic" :values="selectedDemographic.values"
                                :index="index" :isEditMode="true">
              </DemographicModal>
            </v-list-item-title>

            <div v-if="!demographic.isRequired " @click="emitModalOpened(index, $event)"
                 :class="`pl-6 py-1 mb-2 mx-1 hover:bg-box-stroke ${activeActionIndex === 2 ? 'bg-box-stroke' : ''}`">
              <DeleteDemographicModal @deleteDemographic="handleDeleteDemographic"
                                      :demographicTitle="selectedDemographic.demographicTitle" :index="index">
              </DeleteDemographicModal>
            </div>
          </v-list-item>
        </Popover>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeUnmount, onMounted, ref} from 'vue';
import bus from "@/bus"
import Popover from '../../AQuarks/Popover.vue';
import DemographicForm from './DemographicForm.vue';
import DemographicModal from './DemographicModal.vue';
import DeleteDemographicModal from './DeleteDemographicModal.vue';
import {useDemographicsStore} from '@/areas/demographics/store/demographicStore';
import {useUserStore} from "@/areas/users/store/userStore";
import {ActionType} from "@/areas/demographics/model/data/demographics";
import {useRoute, useRouter} from 'vue-router';
import {Entity} from '@/areas/entities/model/data/dtos/entity';
import {UserRole} from "@/areas/users/model/enums/UserRole";
import Demographics from "@/components/Atomic/BAtoms/Employees/Forms/Demographics.vue";


export default defineComponent({
  name: 'Accordion',
  components: {
    Popover,
    DemographicForm,
    DemographicModal,
    DeleteDemographicModal
  },
  props: {
    demographic: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: -1,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const demographicStore = useDemographicsStore()
    const userStore = useUserStore()
    const selectedDemographic = props.demographic;
    const router = useRouter();

    const activeActionIndex = ref<number>(0)
    const active = ref<boolean>(false)
    const adminPriv = ref<boolean>(false);
    const activeEntity = ref<Entity | undefined>(undefined);

    const checkRoles = async () => {
      var state = await userStore.getState();

      activeEntity.value = state.activeEntity

      switch (state.role) {
        case UserRole.SuperAdmin:
          adminPriv.value = true
          break

        case UserRole.OrganisationAdmin:
          adminPriv.value = false
          break

        case UserRole.User:
          await router.push(`/dashboard/assessments/${state.activeEntity.guid}`)
      }
    }

    const actionToPerform: ActionType = 'EDITING_DEMOGRAPHIC'

    const organisationGuid = useRoute().params.organisationGuid as string
    const closePopover = () => {
      activeActionIndex.value = 0
    };

    onMounted(() => {
      checkRoles();
      bus.on('close-popover-menu', closePopover)
    })

    onBeforeUnmount(() => {
      bus.off('close-popover-menu', closePopover)
    })

    return {
      active,
      activeEntity,
      actionToPerform,
      organisationGuid,
      demographicStore,
      activeActionIndex,
      selectedDemographic,
    }
  },
  methods: {
    async handleDeleteDemographic(index: number) {
      if (this.selectedDemographic.guid) {
        const deleteDemographic = await this.demographicStore.deleteDemographic(this.activeEntity?.guid as string, this.selectedDemographic.guid);
        location.reload();
      }
    },
    handleCancel() {
      bus.emit('close-modal')
    },
    toggleAccordion(index: number) {
      if (this.selectedDemographic.demographicEnabled) {
        this.active = !this.active;
        bus.emit('default-accordion-toggled', {index, state: this.active});
      }
    },
    handleMoreIconOrientation(index: number) {
      this.$emit('moreIconToggled', {index})
    },
    emitModalOpened(index: number, event: MouseEvent) {
      event.stopPropagation();
      this.activeActionIndex = index
      bus.emit('modal-opened')
    },
  }
})
</script>