import {defineStore} from 'pinia'
import {EntityUserProxy} from "@/areas/entities/proxy/entityUserProxy";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import {UserValidationGroup} from "@/areas/employees/models/dtos/UserValidationGroup";
import {EmployeeCreateDto} from "@/areas/entities/model/data/dtos/employeeCreateDto";
import {useDemographicEditStore} from "@/areas/demographics/store/DemographicEditStore";
import {UserDemographic} from "@/areas/entities/model/data/dtos/userDemographicDto";
import {EmployeeUpsertType} from "@/areas/employees/models/enums/EmployeeUpsertType";
import {UserRolesEnum} from "@/areas/demographics/model/enums/userRolesEnum";

interface IState {
    employeeDto?: EmployeeDto
    userValidationBlock?: UserValidationGroup
    employeeUpsertType?: EmployeeUpsertType
    entityGuid?: string
    isNewUser: boolean,
    isLoading: boolean,
    error?: string
}

function clearStore(): IState {
    return {
        employeeDto: undefined,
        userValidationBlock: undefined,
        employeeUpsertType: undefined,
        entityGuid: undefined,
        isNewUser: false,
        isLoading: true,
        error: undefined
    }
}

function setUpUserValidationBlock(employeeDto?: EmployeeDto): UserValidationGroup {
    const userValidationGroup = new UserValidationGroup();

    if (employeeDto == null) {
        return userValidationGroup;
    }

    userValidationGroup.email.set(employeeDto.user?.email)
    userValidationGroup.firstName.set(employeeDto.user?.firstName)
    userValidationGroup.lastName.set(employeeDto.user?.lastName)
    userValidationGroup.mobileNumber.set(employeeDto.user?.mobileNumber)
    userValidationGroup.userRole.set(employeeDto.user?.userRole)
    userValidationGroup.dateOfBirth.set(employeeDto.user?.dateOfBirthDate)

    return userValidationGroup;
}

function toDateOnlyString(date: Date | undefined): string | undefined {
    if (!date) {
        return undefined
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based in JS
    const day = date.getDate().toString().padStart(2, '0'); // Get day of the month

    return `${year}-${month}-${day}`;
}

function setDate(dateString: string | undefined): Date | undefined {
    if (!dateString) {
        return undefined
    }

    return new Date(`${dateString}T00:00:00`);
}

export const useEmployeeEditStore = defineStore({
    id: "employeeEditStore",
    state: (): IState => clearStore(),
    actions: {
        async init(entityGuid: string, employeeUpsertType: EmployeeUpsertType, userGuid?: string) {
            await this.withLoading(async () => {
                const entityUserProxy = new EntityUserProxy();

                this.entityGuid = entityGuid

                this.employeeUpsertType = employeeUpsertType

                if (userGuid == undefined) {
                    this.isNewUser = true
                    this.userValidationBlock = setUpUserValidationBlock(undefined)
                    return
                }

                const employeeResult = await entityUserProxy.getUser(entityGuid, userGuid);

                if (!employeeResult.isSuccessful) {
                    this.error = "Employee could not be found"
                    return
                }

                this.employeeDto = employeeResult.content

                if (this.employeeDto?.user) {
                    this.employeeDto.user.dateOfBirthDate = setDate(this.employeeDto.user.dateOfBirth)
                }

                this.userValidationBlock = setUpUserValidationBlock(employeeResult.content)
            })
        },

        async save() {
            await this.withLoading(async () => {
                this.error = undefined

                const userDemographicStore = useDemographicEditStore()

                if (this.employeeUpsertType == EmployeeUpsertType.Assessor) {
                    this.userValidationBlock?.userRole.set(UserRolesEnum.User)

                    await userDemographicStore.init(this.entityGuid!, undefined)
                }

                if (!this.userValidationBlock || !this.userValidationBlock.validateAll()) {
                    this.error = "Some fields are not valid, please complete all and save again"
                    return
                }

                const entityUserProxy = new EntityUserProxy();

                //If it is a new user, we need to make sure that we are not trying to create a user that already exists on the platform
                if (this.isNewUser) {
                    console.log("Checking if an existing user")

                    const existingUserResult = await entityUserProxy.getUserByEmail(this.entityGuid!, this.userValidationBlock.email.value!)

                    if (existingUserResult.isSuccessful) {
                        console.log("This is an existing user on the platform")

                        this.error = "An employee with the same email address already exists on the platform"
                        return
                    }
                }

                const userDemographics = userDemographicStore
                    .populatedDemographics
                    .flatMap(p => p.userDemographic)

                const employeeCreateDto: EmployeeCreateDto = {
                    user: {
                        firstName: this.userValidationBlock.firstName.value!,
                        lastName: this.userValidationBlock.lastName.value!,
                        email: this.userValidationBlock.email.value!,
                        mobileNumber: this.userValidationBlock.mobileNumber.value,
                        dateOfBirth: toDateOnlyString(this.userValidationBlock.dateOfBirth.value),
                        userRole: this.userValidationBlock.userRole.value!,
                        entities: []
                    },
                    employeeUpsertType: this.employeeUpsertType!,
                    userDemographics: userDemographics as UserDemographic[]
                }

                const upsertResult = await entityUserProxy.upsert(this.entityGuid!, employeeCreateDto)

                if (upsertResult.isSuccessful) {
                    this.employeeDto = upsertResult.content!
                    return
                }

                this.error = `Could not complete the request at this time: ${upsertResult.error}`
            })
        },

        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        clearStore() {
            this.$state = clearStore()
        },
    }
});