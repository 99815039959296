<template class="flex flex-col space-y-2">
  <div v-if="!employeeEditStore.isLoading"
       class="flex flex-col w-full mx-auto sm:max-w-2xl min-h-full space-y-4 pt-4 px-2 md:px-0">
    <heading-primary>{{ getTitle() }}</heading-primary>

    <Card class="flex flex-col space-y-8 p-2 w-full">
      <HeadingSecondary>Personal Information</HeadingSecondary>

      <Input
          :label= "`${getAdjective()} First Name*`"
          placeholder="Enter the first name of the employee"
          :required="true"
          :disabled="false"
          :default-value="employeeEditStore.userValidationBlock?.firstName.value"
          :custom-error-message="employeeEditStore.userValidationBlock?.firstName.error"
          class="w-full"

          @value-changed="val => employeeEditStore.userValidationBlock?.firstName.setAndValidate(val)"
      />

      <Input
          :label="`${getAdjective()} Last Name*`"
          placeholder="Enter the last name of the employee"
          :required="true"
          :disabled="false"
          :default-value="employeeEditStore.userValidationBlock?.lastName.value"
          :custom-error-message="employeeEditStore.userValidationBlock?.lastName.error"
          class="w-full"

          @value-changed="val => employeeEditStore.userValidationBlock?.lastName.setAndValidate(val)"
      />

      <Input
          :label="`${getAdjective()} Email Address*`"
          placeholder="Enter the last name of the employee"
          :required="true"
          :disabled="false"
          :default-value="employeeEditStore.userValidationBlock?.email.value"
          :custom-error-message="employeeEditStore.userValidationBlock?.email.error"
          class="w-full"

          @value-changed="val => employeeEditStore.userValidationBlock?.email.setAndValidate(val)"
      />

      <Select
          v-if="employeeEditType == EmployeeUpsertType.Admin"
          :label="`${getAdjective()} Role *`"
          @value-changed="(val)=> employeeEditStore.userValidationBlock?.userRole.setAndValidate(val)"
          :options="[
                {option: 'Member', value: 'User'},
                {option: 'Organisation Admin', value: 'OrganisationAdmin'},
                {option:'Super Admin', value: 'SuperAdmin'},
            ]"
          :selected="employeeEditStore.userValidationBlock?.userRole.value"
          :customErrorMessage="employeeEditStore.userValidationBlock?.userRole.error"/>

      <Input
          v-if="[EmployeeUpsertType.Admin, EmployeeUpsertType.Profile].includes(employeeEditType)"
          :label="`${getAdjective()} Contact Number`"
          placeholder="Enter the contact number of the employee"
          :required="true"
          :disabled="false"
          :default-value="employeeEditStore.userValidationBlock?.mobileNumber.value"
          :custom-error-message="employeeEditStore.userValidationBlock?.mobileNumber.error"
          class="w-full"

          @value-changed="val => employeeEditStore.userValidationBlock?.mobileNumber.setAndValidate(val)"
      />

      <DatePicker
          v-if="[EmployeeUpsertType.Admin, EmployeeUpsertType.Profile].includes(employeeEditType)"
          :label="`${getAdjective()} Date of Birth`"
          :disabled="false"
          :max-date="new Date()"
          :date="employeeEditStore.userValidationBlock?.dateOfBirth.value"
          @updatedDate="val => employeeEditStore.userValidationBlock?.dateOfBirth.setAndValidate(val)"
      />
    </Card>

    <DemographicEdit
        v-if="[EmployeeUpsertType.Admin, EmployeeUpsertType.Profile].includes(employeeEditType)"
        :entity-guid="entityGuid"
        :employee-dto="employeeEditStore.employeeDto"/>

    <div class="flex flex-row md:space-x-8 space-x-2">
      <GenericButton :disabled="employeeEditStore.isLoading" @onSubmit="onSave" class="px-4">
        {{ getSave() }}
      </GenericButton>

      <GenericButton bg-color="bg-wine-red" :disabled="employeeEditStore.isLoading" @onSubmit="_ => confirmCancellation = true" class="px-4" >
        Cancel
      </GenericButton>
    </div>
  </div>

  <LoadingCard :isLoading="employeeEditStore.isLoading"/>

  <PopupConfirmation
      v-if="confirmCancellation"
      title="Cancel"
      @cancel="confirmCancellation = false"
      @confirm="onCancel"
  >
    All changes made on this screen will be lost. Are you sure you want to continue?
  </PopupConfirmation>


</template>

<script setup lang="ts">

import Card from "@/shared/components/Card.vue";
import Input from "@/components/Atomic/AQuarks/Input.vue";
import {onMounted, ref, Ref} from "vue";
import {useEmployeeEditStore} from "@/areas/employees/stores/EmployeeEditStore";
import DatePicker from "@/shared/components/DatePicker.vue";
import HeadingPrimary from "@/shared/components/HeadingPrimary.vue";
import Select from "@/components/Atomic/AQuarks/Select.vue";
import DemographicEdit from "@/areas/demographics/components/DemographicEdit.vue";
import HeadingSecondary from "@/shared/components/HeadingSecondary.vue";
import GenericButton from "@/shared/components/GenericButton.vue";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import {EmployeeUpsertType} from "@/areas/employees/models/enums/EmployeeUpsertType";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";

const props = defineProps<{
  entityGuid: string,
  userGuid?: string,
  employeeEditType: EmployeeUpsertType
}>()

const emits = defineEmits<{
  saved: [EmployeeDto]
  cancelled: []
}>()

const employeeEditStore = useEmployeeEditStore()

const confirmCancellation: Ref<boolean> = ref(false)

const onCancel = () => {
  confirmCancellation.value = false

  emits('cancelled')
}

const getAdjective = (): string => {
  switch (props.employeeEditType) {
    case EmployeeUpsertType.Assessor:
      return "Colleague"
    case EmployeeUpsertType.Admin:
      return "Employee"
    case EmployeeUpsertType.Profile:
      return "Your"
  }
}

const getTitle = (): string =>{
  switch (props.employeeEditType) {
    case EmployeeUpsertType.Assessor:
      return "Add New Assessor"
    case EmployeeUpsertType.Admin:
      return employeeEditStore.isNewUser ?
          "Create New Employee" :
          `Edit Employee ${employeeEditStore.employeeDto?.user.firstName}`
    case EmployeeUpsertType.Profile:
      return "Edit Your Profile"
  }
}

const getSave = (): string => {
  switch (props.employeeEditType) {
    case EmployeeUpsertType.Assessor:
      return "Add Assessor"
    case EmployeeUpsertType.Admin:
      return employeeEditStore.isNewUser ?
          "Add Employee" :
          "Save Employee"
    case EmployeeUpsertType.Profile:
      return "Save"
  }
}

async function onSave() {
  await employeeEditStore.save().then(_ => {

    if (!employeeEditStore.error) {
      emits('saved', employeeEditStore.employeeDto!)

      employeeEditStore.clearStore()

      return
    }

    Toast.error(employeeEditStore.error, toastOptions)
  })
}

onMounted(async () => {
  await employeeEditStore.init(props.entityGuid, props.employeeEditType, props.userGuid)
})

</script>