import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {Operations} from "@/areas/helpers/responses/operations";
import axiosInstance from "@/axiosConfig";
import {DomainDto} from "@/areas/assessmentTypes/model/data/dto/DomainDto";
import {AssessmentTypeCopyDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeCopyDto";

export class AssessmentTypeProxy {
    static async create (entityGuid: string, assessmentTypeDto: AssessmentTypeDto): Promise<OperationResult<AssessmentTypeDto>> {
        const uri = `/entities/${entityGuid}/assessments/types`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.post<AssessmentTypeDto>(uri, assessmentTypeDto)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    static async update (entityGuid: string, assessmentTypeGuid: string, assessmentTypeDto: AssessmentTypeDto): Promise<OperationResult<AssessmentTypeDto>> {
        const uri = `/entities/${entityGuid}/assessments/types/${assessmentTypeGuid}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<AssessmentTypeDto>(uri, assessmentTypeDto)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    static async addDomain (entityGuid: string, assessmentTypeGuid: string, domainDto: DomainDto): Promise<OperationResult<AssessmentTypeDto>> {
        const uri = `/entities/${entityGuid}/assessments/types/${assessmentTypeGuid}/domains`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<AssessmentTypeDto>(uri, domainDto)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    static async publish (entityGuid: string, assessmentTypeGuid: string): Promise<OperationResult<AssessmentTypeDto>> {
        const uri = `/entities/${entityGuid}/assessments/types/${assessmentTypeGuid}/states/Published`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<AssessmentTypeDto>(uri)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    static async delete(entityGuid: string, assessmentTypeGuid: string): Promise<OperationResult<any>> {
        const uri = `entities/${entityGuid}/assessments/types/${assessmentTypeGuid}`

        return await Operations.WrapRequest(async () => {
            const response = axiosInstance.delete(uri)

            return Operations.ToSuccessResponse(response);
        })
    }

    static async list (entityGuid: string): Promise<OperationResult<AssessmentTypeDto[]>> {
        const uri = `/entities/${entityGuid}/assessments/types`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get(uri)

            return Operations.ToSuccessResponse(response.data as AssessmentTypeDto[])
        })
    }

    static async get (entityGuid: string, assessmentTypeGuid: string): Promise<OperationResult<AssessmentTypeDto>> {
        const uri = `entities/${entityGuid}/assessments/types/${assessmentTypeGuid}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get(uri)

            return Operations.ToSuccessResponse(response.data as AssessmentTypeDto)
        })
    }

    static async copy (entityGuid: string, assessmentTypeCopyDto: AssessmentTypeCopyDto): Promise<OperationResult<AssessmentTypeDto>> {
        const uri = `entities/${entityGuid}/assessments/types/copies`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<AssessmentTypeDto>(uri, assessmentTypeCopyDto)

            return Operations.ToSuccessResponse(response.data as AssessmentTypeDto)
        })
    }
}