import axiosInstance from "@/axiosConfig";
import {Operations} from "@/areas/helpers/responses/operations";
import {AssessmentCreateDto} from "../model/dtos/assessmentCreateDto";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {AssessmentDto} from "@/areas/assessments/model/dtos/assessmentDto";
import {AssessmentDetailDto} from "@/areas/assessments/model/dtos/assessmentDetailDto";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import qs from 'qs';
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {UserAssessmentDto} from "@/areas/assessments/model/dtos/UserAssessmentDto";

export class AssessmentProxy {
    public async create(entityGuid: string, assessmentCreateDto: AssessmentCreateDto): Promise<OperationResult<AssessmentDto>> {
        const uri = `/entities/${entityGuid}/assessments`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.post<AssessmentDto>(uri, assessmentCreateDto)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async update(entityGuid: string, assessmentGuid: string, detailDto: AssessmentDetailDto): Promise<OperationResult<AssessmentDto>> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}`

        return Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<AssessmentDto>(uri, detailDto)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async setState(entityGuid: string, assessmentGuid: string, state: AssessmentState): Promise<OperationResult<AssessmentDto>> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}/states/${state.toString()}`

        return Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<AssessmentDto>(uri)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async list(entityGuid: string): Promise<OperationResult<AssessmentDto[]>> {
        const uri = `/entities/${entityGuid}/assessments`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<AssessmentDto[]>(uri)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async getByGuid(entityGuid: string, assessmentGuid: string, includeDeleted: boolean = false): Promise<OperationResult<AssessmentDto>> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<AssessmentDto>(uri, {
                params: {
                    includeDeleted: includeDeleted,
                },
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
            })

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async listByUser(entityGuid: string, assessmentStates: AssessmentState[], assessorStates: AssessorState[]): Promise<OperationResult<UserAssessmentDto[]>> {
        const uri = `/entities/${entityGuid}/assessments/users`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<UserAssessmentDto[]>(uri, {
                params: {
                    assessmentStates: assessmentStates,
                    assessorStates: assessorStates
                },
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
            })

            return Operations.ToSuccessResponse(response.data)
        })
    }
}