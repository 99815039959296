<template>
  <Card v-if="!demographicEditStore.isLoading" class="space-y-4">
    <HeadingSecondary>Demographics</HeadingSecondary>

    <Select
        v-for="populateDemographic in demographicEditStore.populatedDemographics"
        :options="populateDemographic.options"
        :key="populateDemographic.demographic.guid!"
        :label="populateDemographic.demographic.displayName"
        :selected="populateDemographic.userDemographic?.answer"

        @value-changed="val => updateSelection(populateDemographic.demographic.guid!, val)"
        />
  </Card>

  <LoadingCard :isLoading="demographicEditStore.isLoading"></LoadingCard>
</template>

<script setup lang="ts">
import Card from "@/shared/components/Card.vue";
import {onMounted} from "vue";
import {useDemographicEditStore} from "@/areas/demographics/store/DemographicEditStore";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import Select from "@/components/Atomic/AQuarks/Select.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import HeadingSecondary from "@/shared/components/HeadingSecondary.vue";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";

const props = defineProps<{
  entityGuid: string,
  employeeDto?: EmployeeDto
}>()

const demographicEditStore = useDemographicEditStore();

const updateSelection = (demographicGuid: string, option?: string) => {
  console.log(demographicGuid, option)

  demographicEditStore.select(demographicGuid, option);

  if (demographicEditStore.error) {
    Toast.error(demographicEditStore.error, toastOptions)
  }

  demographicEditStore.clearError()
}

onMounted(() => {
  demographicEditStore.init(props.entityGuid, props.employeeDto);
})

</script>