import { inRange } from "lodash";
import {
  IRange, 
  ISharedProps,
  IAccordionProps,
  DemographicNew
} from "@/areas/demographics/model/data/demographics";
import { DateTime } from "luxon";
import { Demographic } from "@/areas/demographics/model/data/dtos/demographic";

/**@param ranges the array of ranges to check
 * @returns false if there's no overlap and true otherwise
 */
export const hasOverlap = (ranges: IRange[]) => {
  let overlapDetected = false;
  for (let i = 0; i < ranges.length - 1; i++) {
    for (let j = i + 1; j < ranges.length; j++) {
      const range1 = ranges[i].value;
      const range2 = ranges[j].value;

      if (
        inRange(range2[0], range1[0], range1[1] + 1) ||
        inRange(range1[0], range2[0], range2[1] + 1)
      ) {
        overlapDetected = true;
      }
    }
  }
  return overlapDetected;
};

export const convertDemographicToAccordionParam = (
  demographicNew: DemographicNew
): IAccordionProps => {

  const { demographic, guid, isEnabled } = demographicNew;

  let values = [];

  const demographicSharedProps: ISharedProps = {
    demographicEnabled: isEnabled,
    demographicTitle: demographic.displayName,
    demographicType: demographic.demographicTypeEnum,
    guid: demographic.guid || '',
    entityDemographicGuid: guid || ''
  };

  if (demographic.demographicTypeEnum === 'NumberedRange') {
    values = demographic.demographicVersions?.flatMap(demographicVersion => 
      demographicVersion.payload.map(({ title, min, max }) => ({
        title,
        value: [min, max]
      }))
    ) || [];
  } else {
    values = demographic.demographicCategories?.map(({ guid, title }) => ({
      guid,
      title
    })) || [];
  }
    
    return {
      ...demographicSharedProps,
      values,
    };
};

export const convertDemographicToDefaultDemoGraphicsTable = (
  demographicNew: Demographic
): IAccordionProps => {
  const { isEnabled } = demographicNew;

  let values = [];

  const demographicSharedProps: ISharedProps = {
    demographicEnabled: isEnabled,
    demographicTitle: demographicNew.displayName,
    demographicType: demographicNew.demographicType,
    guid: demographicNew.guid || '',
  };

  if (demographicNew.demographicType === 'NumberedRange') {
    values = demographicNew.demographicVersions?.flatMap(demographicVersion => 
      demographicVersion.payload.map(({ title, min, max }) => ({
        title,
        value: [min, max]
      }))
    ) || [];
  } else {
    values = demographicNew.demographicVersions?.flatMap(demographicVersion => 
      demographicVersion.payload.map(({ title }) => ({
        title
      }))
    ) || [];
  }
    
    return {
      ...demographicSharedProps,
      values,
    };
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export const dateFormatter = (date: string, format = 'dd/MM/yyyy')=>{
  return DateTime.fromFormat(date, format, { zone: 'utc' })
}

export const calculateAge = (dob: Date): number => {
  const currentYear = new Date().getFullYear();
  const birthYear = dob.getFullYear();
  return currentYear - birthYear;
}

export const isValidAndAcceptableDate = (date: string)=>{
  const parsedDate = dateFormatter(date)
  if(parsedDate.isValid){
    const age = calculateAge(parsedDate.toJSDate())
    return age > 17
  }

  return false
}

