import { ValidationBlock } from "@/areas/helpers/validations/ValidationBlock";

export abstract class ValidationGroup {
    /**
     * Validates all `ValidationBlock` instances in the derived class.
     * Returns true if all validations pass, otherwise false.
     */
    validateAll(): boolean {
        const validationBlocks = Object.values(this) as ValidationBlock<any>[];

        var areAllValid = true

        for (const block of validationBlocks) {
            const isValid = block.validate()

            if (!isValid) {
                areAllValid = false
            }
        }

        return areAllValid
    }
}