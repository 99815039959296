import {defineStore} from "pinia";
import {AssessmentProxy} from "@/areas/assessments/proxy/assessmentProxy";
import {AssessorDto} from "@/areas/assessors/models/dtos/assessorDto";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {AssessorProxy} from "@/areas/assessors/proxies/AssessorProxy";
import {DelegateProxy} from "@/areas/delegates/proxy/delegateProxy";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import {AssessmentTypeProxy} from "@/areas/assessmentTypes/proxy/assessmentTypeProxy";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";
import {ReportAssessment} from "@/areas/reports/models/data/ReportAssessment";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {DomainType} from "@/areas/assessmentTypes/model/data/enums/DomainType";
import {ReportDomain} from "@/areas/reports/models/data/ReportDomain";
import {DomainDto} from "@/areas/assessmentTypes/model/data/dto/DomainDto";
import {ReportQuestion} from "@/areas/reports/models/data/ReportQuestion";
import {QuestionDto} from "@/areas/assessmentTypes/model/data/dto/QuestionDto";
import {QuestionResultDto} from "@/areas/userAssessments/models/dtos/QuestionResultDto";
import {DomainResultDto} from "@/areas/userAssessments/models/dtos/DomainResultDto";
import {CommentResultDto} from "@/areas/userAssessments/models/dtos/CommentResultDto";
import {CommentResultType} from "@/areas/userAssessments/models/enums/CommentResultType";
import {sortBy} from "lodash";

interface ReportStore {
    assessment: AssessmentDisplayObject | undefined,

    assessmentType: AssessmentTypeDto | undefined,

    delegate: DelegateDto | undefined,

    stats: ReportStats | undefined,

    reportAssessment: ReportAssessment | undefined,

    assessors: AssessorDto[],

    entityGuid: string | undefined,

    isLoading: boolean

    error?: string
}

export interface ReportStats {
    totalAssessors: number,

    totalCompletedAssessors: number,

    totalCompletedDirect: number,

    totalCompletedPeers: number,

    totalCompletedLeader: number
}

function emptyState(): ReportStore {
    return {
        assessment: undefined,

        assessmentType: undefined,

        delegate: undefined,

        stats: undefined,

        reportAssessment: undefined,

        assessors: [],

        entityGuid: undefined,

        isLoading: true,

        error: undefined,
    }
}

function getStats(state: ReportStore): ReportStats {
    return {
        totalAssessors: state.assessors.filter(ass =>
            ass.entityState != AssessorState.Removed && ass.entityState != AssessorState.Declined).length,

        totalCompletedAssessors: state.assessors.filter(ass =>
            ass.entityState == AssessorState.Completed).length,

        totalCompletedPeers: state.assessors.filter(ass =>
            ass.entityState == AssessorState.Completed &&
            ass.assessorRelationshipType == AssessorRelationshipType.Peer && !isDelegate(ass, state.delegate!)).length,

        totalCompletedDirect: state.assessors.filter(ass =>
            ass.entityState == AssessorState.Completed &&
            ass.assessorRelationshipType == AssessorRelationshipType.DirectReport && !isDelegate(ass, state.delegate!)).length,

        totalCompletedLeader: state.assessors.filter(ass =>
            ass.entityState == AssessorState.Completed &&
            ass.assessorRelationshipType == AssessorRelationshipType.Leader && !isDelegate(ass, state.delegate!)).length
    }
}

function isDelegate(assessor: AssessorDto, delegate: DelegateDto): boolean {
    return delegate.delegateType == DelegateType.Individual && delegate.individual!.guid == assessor.user!.guid
}

async function buildReportAssessment(state: ReportStore): Promise<ReportAssessment> {
    let report = createEmptyReport(state.assessmentType!, state.assessment!, state.delegate!)

    const assessorProxy = new AssessorProxy()


    for (const assessor of state.assessors) {
        let isMine = false

        if (assessor.entityState != AssessorState.Completed) {
            continue
        }

        if (state.delegate?.delegateType == DelegateType.Individual && assessor.user?.guid == state.delegate?.individual?.guid) {
            isMine = true
        }

        const commentResults = await assessorProxy.listResultComments(state.entityGuid!, state.assessment!.guid!, state.delegate!.guid!, assessor.guid!)

        if (!commentResults.isSuccessful) {
            return report
        }

        report = mapReportAssessment(report, assessor, commentResults.content!, isMine)
    }

    return report
}

const createEmptyReport = (assessmentType: AssessmentTypeDto, assessment: AssessmentDisplayObject, delegate: DelegateDto): ReportAssessment => {
    const domains = assessmentType.domains.filter(d => d.deletedAt == null)

    const welcomeDomain = domains.filter(d => d.domainType == DomainType.Welcome)

    const welcomeReportDomains = welcomeDomain.map(d => newReportDomain(d))

    const assessDomains = sortBy(domains.filter(d => d.domainType == DomainType.AssessDomain), "key")

    return {
        welcomeDomain: welcomeReportDomains.length > 0 ? welcomeReportDomains[0] : undefined,
        welcomeTotalAverageMine: 0,
        welcomeTotalAverageOther: 0,
        assessmentDetail: assessment.detail,
        delegate: delegate,
        welcomeOtherComments: [],
        welcomeMineComment: undefined,
        guid: assessment.guid,
        assessDomains: assessDomains.map(d => newReportDomain(d)),
        awayOtherComments: [],
        awayMineComment: undefined,
        towardOtherComments: [],
        towardMineComment: undefined,
        mineTotalAverage: 0,
        otherTotalAverage: 0,
    }
}

const mapReportAssessment = (reportAssessment: ReportAssessment, assessor: AssessorDto, resultComments: CommentResultDto[], isMine: boolean): ReportAssessment => {

    reportAssessment.assessDomains = reportAssessment.assessDomains.map(reportDomain => {
        const domainResult = assessor.domainResults?.find(dr => dr.domain.guid == reportDomain.domain.guid)

        if (!domainResult) {
            console.error("Found a domain that does not have an answer, this should not be possible: ", assessor.guid)
            throw new DOMException("Cannot find assess domain")
        }

        return mapReportDomain(reportDomain, domainResult, isMine)
    })

    //Set the welcome information
    const welcomeDomainResult = assessor.domainResults?.find(d => d.domain.domainType == DomainType.Welcome)

    if (welcomeDomainResult == null) {
        console.error("No welcome domain found")
        throw new DOMException("No welcome domain found")
    }

    reportAssessment.welcomeDomain = mapReportDomain(reportAssessment.welcomeDomain!, welcomeDomainResult, isMine)

    //Get the away/towards comments
    const awayComment = resultComments.find(r => r.commentResultType == CommentResultType.Away)
    const towardComment = resultComments.find(r => r.commentResultType == CommentResultType.Toward)

    //Get the completion information
    if (isMine) {
        reportAssessment.welcomeMineComment = welcomeDomainResult.comments

        reportAssessment.awayMineComment = awayComment?.comment
        reportAssessment.towardMineComment = towardComment?.comment

        reportAssessment.welcomeTotalAverageMine = reportAssessment.welcomeDomain!.mineDomainAverage

        reportAssessment.mineTotalAverage = reportAssessment.assessDomains.reduce(
            (average, reportDomain, index) =>
                ((average * index) + reportDomain.mineDomainAverage!) / (index + 1), 0)
    } else {
        reportAssessment.welcomeOtherComments.push(welcomeDomainResult.comments)

        reportAssessment.awayOtherComments.push(awayComment?.comment!)
        reportAssessment.towardOtherComments.push(towardComment?.comment!)

        reportAssessment.welcomeTotalAverageOther = reportAssessment.welcomeDomain!.otherDomainAverage

        reportAssessment.otherTotalAverage = reportAssessment.assessDomains.reduce(
            (average, reportDomain, index) =>
                ((average * index) + reportDomain.otherDomainAverage!) / (index + 1), 0)

    }

    //Set the completion information
    return reportAssessment
}

const mapReportDomain = (reportDomain: ReportDomain, domainResult: DomainResultDto, isMine: boolean): ReportDomain => {

    reportDomain.reportQuestions = reportDomain.reportQuestions.map(reportQuestion => {
        const question = domainResult.questionResults.find(qr => qr.question.guid == reportQuestion.question.guid)

        if (question == null) {
            console.error("Found a question that does not have an answer, this should not be possible: ", reportQuestion.question.guid, reportDomain.domain.guid)
            throw new DOMException("Cannot find answer")
        }

        return mapReportQuestion(reportQuestion, question, isMine)
    })

    //The difference here is the difference in field name
    if (isMine) {
        reportDomain.mineDomainAverage = reportDomain.reportQuestions.reduce(
            (average, reportQuestion, index) =>
                ((average * index) + reportQuestion.resultsMine!) / (index + 1), 0)

        reportDomain.mineComment = domainResult.comments
    } else {
        reportDomain.otherDomainAverage = reportDomain.reportQuestions.reduce(
            (average, reportQuestion, index) =>
                ((average * index) + reportQuestion.resultsOtherAverage!) / (index + 1), 0)

        reportDomain.otherComments.push(domainResult.comments)
    }

    return reportDomain
}

const mapReportQuestion = (reportQuestion: ReportQuestion, result: QuestionResultDto, isMine: boolean): ReportQuestion => {
    if (!result.rating) {
        return reportQuestion
    }

    if (isMine) {
        reportQuestion.resultsMine = result.rating

        return reportQuestion
    }

    const currentAverage = reportQuestion.resultsOtherAverage
    const currentCount = reportQuestion.resultsOther.length

    reportQuestion.resultsOtherAverage = ((currentAverage * currentCount) + result.rating) / (currentCount + 1)
    reportQuestion.resultsOther.push(result.rating)

    return reportQuestion
}

const newReportDomain = (domainDto: DomainDto): ReportDomain => {
    return {
        mineDomainAverage: 0,
        domain: domainDto,
        reportQuestions: domainDto.questions.map(q => newReportQuestion(q)),
        otherDomainAverage: 0,
        otherComments: [],
        mineComment: ''
    }
}

const newReportQuestion = (questionDto: QuestionDto): ReportQuestion => {
    return {
        resultsMine: 0,
        resultsOther: [],
        resultsOtherAverage: 0,
        question: questionDto
    }
}

export const getDomainQuestionResults = (domain: ReportDomain): number[] => {
    return domain.reportQuestions.flatMap(q => q.resultsOther)
}

export const useReportStore = defineStore({
    id: "reportStore",
    state: (): ReportStore => emptyState(),
    actions: {
        async init(entityGuid: string, assessmentGuid: string, delegateGuid: string) {
            const assessmentProxy = new AssessmentProxy();
            const delegateProxy = new DelegateProxy()
            const assessorProxy = new AssessorProxy()

            const assessmentFetchResult = await assessmentProxy.getByGuid(entityGuid, assessmentGuid, true)

            if (!assessmentFetchResult.isSuccessful) {
                this.error = "The assessment could not be found"
                return
            }

            const assessmentExtensions = new AssessmentDOExtensions()

            this.assessment = assessmentExtensions.castToAssessmentDisplayObject(assessmentFetchResult.content!)

            const delegateFetchResult = await delegateProxy.get(entityGuid, assessmentGuid, delegateGuid);

            if (!delegateFetchResult.isSuccessful) {
                this.error = "The delegate could not be found"
                return
            }

            this.delegate = delegateFetchResult.content!

            const assessorResults = await assessorProxy.listResultsByDelegate(entityGuid, assessmentGuid, delegateGuid);

            if (!assessorResults.isSuccessful) {
                this.error = "The assessors could not be found"
                return
            }

            this.assessors = assessorResults.content!

            const assessmentTypeGuid = this.assessment.assessment.entityAssessmentType!.assessmentType.guid!

            const assessmentTypeFetchResult = await AssessmentTypeProxy.get(entityGuid, assessmentTypeGuid)

            if (!assessmentTypeFetchResult.isSuccessful) {
                this.error = "The assessment type could not be found"
                return
            }

            this.assessmentType = assessmentTypeFetchResult.content!

            this.entityGuid = entityGuid

            this.reportAssessment = await buildReportAssessment(this.$state)

            this.stats = getStats(this.$state)
        },
    },

})