<template>
  <AppLayout>
    <EmployeeEdit
        :entity-guid="entityGuid"
        :user-guid="userGuid"
        :employee-edit-type="EmployeeUpsertType.Admin"
        @saved="onComplete"
        @cancelled="onComplete"/>
  </AppLayout>
</template>

<script lang="ts" setup>
import AppLayout from '@/components/Layouts/AppLayout.vue';
import {useRoute} from "vue-router";
import {computed} from "vue";
import EmployeeEdit from "@/areas/employees/components/EmployeeEdit.vue";
import router from "@/router";
import {EmployeeUpsertType} from "@/areas/employees/models/enums/EmployeeUpsertType";

const route = useRoute();

const entityGuid = computed(() => route.params.entityGuid as string);
const userGuid = computed(() => (route.params.userGuid ? (route.params.userGuid as string) : undefined));

const onComplete = async () => {
  await router.push({ name: 'employees', params: { organisationGuid: entityGuid.value } })
}
</script>