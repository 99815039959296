<template>
  <div v-if="!addingAssessors">
    <SearchInputCard class="w-full" placeholder="Search Assessors" @searchUpdate="search" />

    <div class="flex flex-col justify-center items-center space-y-4">
      <div v-if="assessorStore.assessorDisplayObjects.length === 0" class="pt-4">

        <Card>
          <div class="py-4 font-roboto text-center text-payne-grey">
            You do not have any assessors added to this assessment. Please add assessors by selecting the plus button.
          </div>
        </Card>

      </div>

      <div v-else class="pt-4 w-full">
        <div v-for="assessor in filteredAssessors" :key="assessor.employee.user.guid" class="py-2">
          <AssessorCard
              v-if="assessorStore.assessmentDo?.assessment"
              :assessment-state="assessorStore.assessmentDo.assessment.entityState"
              :assessorDisplayObject="assessor"
              :require-relationship="showRelationship(assessor)"
              @delete="onDeleteAssessor(assessor)"
              @updateRelationship="val => setRelationship(assessor, val)"
              @viewResults="emit('viewResults', assessor)"
              @sendReminder="sendReminder(assessor)"
          />
        </div>
      </div>
    </div>

    <div class="pb-16"></div>
  </div>

  <AddParticipants
      v-if="addingAssessors"
      :entityGuid="entityGuid"
      :selected-users="assessorStore.assessorDisplayObjects.map(ad => ad.employee.user)"
      :selectable="true"
      :can-deselect="true"
      :can-create="true"
      heading="Add Assessors"
      @back="emit('back')"
      @save="addAssessors"
  />
</template>

<script setup lang="ts">
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";
import Card from "@/shared/components/Card.vue";
import {ref, Ref, watch} from "vue";
import AddParticipants from "@/areas/participants/components/AddParticipants.vue";
import AssessorCard from "@/areas/assessors/components/AssessorCard.vue";
import {useAssessorStore} from "@/areas/assessors/store/AssessorStore";
import {ParticipantDo} from "@/areas/participants/models/dos/ParticipantDo";
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";
import SearchInputCard from "@/shared/components/SearchInputCard.vue";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import {DelegateDisplayItem} from "@/areas/delegates/model/dos/DelegateDisplayItem";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";

const props = defineProps<{
  heading: string
  addAssessor: boolean
  entityGuid: string
  requireRelationship: boolean
  delegate: DelegateDisplayItem
}>()

const emit = defineEmits<{
  update: []
  updateRelationship: [AssessorDisplayObject, AssessorRelationshipType]
  back: []
  viewResults: [AssessorDisplayObject]
}>()

const addingAssessors: Ref<boolean> = ref(props.addAssessor)

const assessorStore = useAssessorStore()

const currentSearchString: Ref<string> = ref('')

const filteredAssessors: Ref<AssessorDisplayObject[]> = ref(assessorStore.assessorDisplayObjects)

watch(() => assessorStore.assessorDisplayObjects,
    () => {
      filteredAssessors.value = assessorStore.assessorDisplayObjects
      search(currentSearchString.value)
    }
)

watch(() => props.addAssessor, (newValue) => {
  addingAssessors.value  = newValue
})

const showRelationship = (assessor: AssessorDisplayObject): boolean => {
  if (!props.requireRelationship) {
    return false
  }

  return !(props.delegate.delegateType == DelegateType.Individual && props.delegate.individual?.guid == assessor.employee.user.guid)
}

const onDeleteAssessor = (assessorDisplayObject: AssessorDisplayObject) => {
  assessorStore.deleteAssessor(assessorDisplayObject)

  emit('update')
}

const addAssessors = (participateDo: ParticipantDo) => {
  assessorStore.addAssessor(participateDo)

  emit('update')
}

const setRelationship = (assessor: AssessorDisplayObject, relationship: AssessorRelationshipType) => {
  assessorStore.setRelationship(assessor, relationship)

  emit('update')
}

const sendReminder = (assessor: AssessorDisplayObject) => {
  assessorStore.sendReminder(assessor)

  if (!assessorStore.error) {
    Toast.success("Reminder successfully sent", toastOptions)
  }
}

const search = (searchString: string) => {
  const lowerSearchString = searchString.toLowerCase()

  currentSearchString.value = searchString

  if (lowerSearchString == '') {
    filteredAssessors.value = assessorStore.assessorDisplayObjects
    return
  }

  filteredAssessors.value = assessorStore.assessorDisplayObjects.filter(ado =>
      (`${ado.employee.user.firstName} ${ado.employee.user.lastName}`.toLowerCase().includes(lowerSearchString))
  )
}

</script>