import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {
    Assessments,
    AssessmentType,
    DefaultDemographics,
    EmployeesView,
    HomeView,
    OrganisationView,
} from '@/views'

import UserAssessments from "@/areas/userAssessments/views/UserAssessments.vue";
import UserAssessors from "@/areas/userAssessments/views/UserAssessors.vue";
import {useUserStore} from "@/areas/users/store/userStore";
import {UserRole} from "@/areas/users/model/enums/UserRole";
import UserInvites from "@/areas/userAssessments/views/UserInvites.vue";
import UserAssessmentCompletion from "@/areas/userAssessments/views/UserAssessmentCompletion.vue";
import EmployeeEditPage from "@/areas/employees/pages/EmployeeEditPage.vue";
import AssessmentTypeMenu from "@/areas/assessmentTypes/views/AssessmentTypeMenu.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/dashboards/entities/:entityGuid/assessments',
        name: "Assessments2",
        component: Assessments,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin]
        }
    },
    {
        path: '/dashboard/organisation/:organisationGuid?',
        name: 'organisation',
        component: OrganisationView
    },
    {
        path: '/dashboard/assessments/:organisationGuid?',
        name: 'assessments',
        component: Assessments
    },
    {
        path: '/dashboard/employees/:organisationGuid?',
        name: 'employees',
        component: EmployeesView
    },
    {
        path: '/dashboard/entities/:entityGuid/assessments/types',
        name: 'assessmentTypeView',
        component: AssessmentType,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin]
        }
    },
    {
        path: '/dashboard/entities/:entityGuid/employees/:userGuid',
        name: 'employeeEditPage',
        component: EmployeeEditPage,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin]
        }
    },
    {
        path: '/dashboard/entities/:entityGuid/employees',
        name: 'employeeNewPage',
        component: EmployeeEditPage,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin]
        }
    },
    {
        path: '/dashboard/menus/entities/:entityGuid/assessments/types',
        name: 'assessmentTypeViewMenu',
        component: AssessmentTypeMenu,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin]
        }
    },
    {
        path: '/dashboard/demographics/default',
        name: 'defaultDemographics',
        component: DefaultDemographics
    },
    {
        path: '/dashboard/entities/:entityGuid/users/assessments',
        name: 'UserAssessments',
        component: UserAssessments,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin, UserRole.User]
        }
    },
    {
        path: '/dashboard/entities/:entityGuid/users/assessments/:assessmentGuid/delegates/:delegateGuid/assessors',
        name: 'UserAssessors',
        component: UserAssessors,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin, UserRole.User]
        }
    },
    {
        path: '/dashboard/entities/:entityGuid/users/assessments/:assessmentGuid/delegates/:delegateGuid/assessors/:assessorGuid/invites/:status',
        name: 'UserParticipation',
        component: UserInvites,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin, UserRole.User]
        }
    },
    {
        path: '/dashboard/entities/:entityGuid/users/assessments/:assessmentGuid/delegates/:delegateGuid/assessors/:assessorGuid/completions',
        name: 'UserAssessmentCompletion',
        component: UserAssessmentCompletion,
        meta: {
            roles: [UserRole.SuperAdmin, UserRole.OrganisationAdmin, UserRole.User]
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach( async (to, from, next) => {
    const userStore = useUserStore()

    const user = await userStore.getState()

    const role = user.role

    const routeRoles = to.meta.roles as UserRole[]

    const entityGuid = to.params.entityGuid

    if (routeRoles) {
        if (routeRoles.includes(role)) {
            next()

            return
        } else {
            next({
                name:"UserAssessments",
                params: {
                    entityGuid: entityGuid
                }
            })

            return
        }
    }

    next()
})

export default router