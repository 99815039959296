export class ValidationBlock<T> {
    value?: T;
    isValid: boolean;
    error?: string;
    validators: Array<(value?: T) => string | undefined>;

    constructor(
        value?: T,
        validators: Array<(value?: T) => string | undefined> = [],
    ) {
        this.value = value;
        this.isValid = false; // Default to false until validation runs
        this.error = undefined;
        this.validators = validators;
    }

    /**
     * Run all validators and update isValid and error properties.
     */
    validate(): boolean {
        for (const validator of this.validators) {
            const errorMessage = validator(this.value)

            if (errorMessage != undefined) {
                this.isValid = false
                this.error = errorMessage
                return false
            }
        }

        this.isValid = true
        this.error = undefined
        return true
    }

    setAndValidate(value?: T) {
        this.value = value;
        this.validate();
    }

    set(value?: T) {
        this.value = value;
    }

    /**
     * Add a new validator to the validators list.
     */
    addValidator(validator: (value?: T) => string | undefined): void {
        this.validators.push(validator);
    }
}