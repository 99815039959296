<template>
  <div class="w-full flex flex-col space-y-2">
    <NavHeading
        v-if="!addingUser"
        :heading="heading"
        @back="emit('back')"
        class="py-4">
    </NavHeading>

    <div class="flex flex-row justify-end">
      <AddButton
          v-if="canCreate && !addingUser"
          class="md:w-1/3 w-full"
          :disabled="selection !== DelegateType.Individual"
          heading="New User"
          @add="addingUser = true"/>
    </div>

    <EmployeeEdit
        v-if="addingUser"
        :entity-guid="props.entityGuid"
        :employee-edit-type="EmployeeUpsertType.Assessor"
        @cancelled="addingUser=false"
        @saved="newUserCreated"/>

    <div v-if="!addingUser">
      <Card>
        <TabNavigation
            :selected-tab="selectedTab"
            :tabs="['By Individual', 'By Team']"
            @tabChange="tabChange"/>
      </Card>

      <LoadingCard :is-loading="!selectedTeam && participantStore.isLoading"/>

      <!--    Select by INDIVIDUAL-->
      <div v-if="selection === DelegateType.Individual" class="">
        <EmployeeSearch
            :entity-guid="entityGuid"
            :selected-users="selectedUsers"
            :selectable="selectable"
            :canDeselect="canDeselect"
            @selected="addIndividual"
            @deselected="removeIndividual"
        />

        <div v-if="selectable" class="pb-10"/>

        <SaveButton
            v-if="selectable"
            class="px-2"
            :disabled="false"
            :is-loading="participantStore.isLoading"
            @save="save"
            text="Add"
        />

      </div>

      <!-- Select by TEAM-->
      <div v-if="selection === DelegateType.Team" class="">
        <Card>
          <Select
              placeholder="Select a team"
              :options="teamOptions()"
              label="Select a team"
              @value-changed="changeTeam"
          />
        </Card>

        <LoadingCard :isLoading="!!(selectedTeam && participantStore.isLoading)"/>

        <div v-if="participantStore.employees" class="space-y-4 pt-4">
          <GenericButton @onSubmit="save" class="bg-olive-green text-white">Save</GenericButton>
        </div>

      </div>
    </div>

  </div>
</template>

<script setup lang="ts">

import TabNavigation from "@/shared/components/TabNavigation.vue";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {defineProps, onMounted, ref, Ref} from "vue";
import EmployeeSearch from "@/areas/employees/components/EmployeeSearch.vue";
import Card from "@/shared/components/Card.vue";
import Select from "@/components/Atomic/AQuarks/Select.vue";
import NavHeading from "@/shared/components/NavHeading.vue";
import GenericButton from "@/shared/components/GenericButton.vue";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import {useParticipantStore} from "@/areas/participants/stores/ParticipantStore";
import {ParticipantDo} from "@/areas/participants/models/dos/ParticipantDo";
import SaveButton from "@/shared/components/SaveButton.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import {User} from "@/areas/users/model/data/User";
import AddButton from "@/shared/components/AddButton.vue";
import EmployeeEdit from "@/areas/employees/components/EmployeeEdit.vue";
import {EmployeeUpsertType} from "@/areas/employees/models/enums/EmployeeUpsertType";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";

const props = defineProps<{
  //The entityGuid of the organisation that we are working on
  entityGuid: string

  //The heading for the top of the add participants screen
  heading: string

  //The users that should show as selected
  selectedUsers: User[]

  //Show a select icon next to the list of individuals. This will also add a select all feature at the top of the screen
  selectable: boolean

  //Allow the list of items that are already selected as passed in as 'selectedUsers' above to be deselected
  canDeselect: boolean

  //Allow the person to create (this is only for use with Assessors at the moment
  canCreate: boolean
}>()

const emit = defineEmits<{
  back: []
  save: [participantDo: ParticipantDo]
}>()

const selection: Ref<DelegateType> = ref(DelegateType.Individual)

const selectedTab = ref<string>("By Individual")

const participantStore = useParticipantStore()

const selectedTeam: Ref<string | undefined> = ref(undefined)

const addingUser: Ref<boolean> = ref(false)

const teamOptions = () => {
  return participantStore.teams?.map(t => {
    return {
      option: t,
      value: t
    }
  })
}

const changeTeam = async (categoryValue: string) => {
  selectedTeam.value = categoryValue

  await participantStore.setTeamEmployees(categoryValue)
}

const addIndividual = (employee: EmployeeDto) => {
  participantStore.addEmployee(employee)

  if (!props.selectable) {
    save()
  }
}

const removeIndividual = (employee: EmployeeDto) => {
  participantStore.removeEmployee(employee)
}

const newUserCreated = async (selectedUser: EmployeeDto) => {
  addIndividual(selectedUser)

  await participantStore.refreshStore()

  addingUser.value = false

  Toast.success("New Assessor added successfully", toastOptions)
}

const save = () => {
  if (participantStore.employees) {
    emit('save', participantStore.getParticipantDo())

    participantStore.clearState()
  }
}

const tabChange = (tabName: string) => {
  participantStore.clearTeamEmployees()
  participantStore.teamName = undefined

  if (tabName === "By Individual") {
    selection.value = DelegateType.Individual
    selectedTab.value = "By Individual"
  } else {
    selection.value = DelegateType.Team
    selectedTab.value = "By Team"
  }
}

onMounted(() => {
  participantStore.init(props.entityGuid)
})

</script>